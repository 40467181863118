import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { loadUpgradableSites } from 'src/client/redux/modules/marketing';

const showUpgradeStripCookie = 'showUpgradeStrip';

export const useUpgradeStrip = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['showFreeAccess', showUpgradeStripCookie]);

  const { showUpgradeStrip, hasUpgradableSites } = useSelector((state) => state.app.config);
  const upgradableSites = useSelector((state) => state.marketing.upgradableSites);
  const user = useSelector((state) => state.auth.user);
  const [upgradeStripOpen, setUpgradeStripOpen] = useState(false);
  const dispatch = useDispatch();
  const freeAccessCookie = cookies.showFreeAccess;
  const hasDisableUpgradeStripCookie = JSON.parse(cookies.showUpgradeStrip || 'true') === false;

  useEffect(() => {
    if (
      user?.validSubscription && // this upgrade strip should only be available to users on sites with active subs
      showUpgradeStrip &&
      upgradableSites.length &&
      !user.showMembersPortal &&
      !(freeAccessCookie || hasDisableUpgradeStripCookie)
    ) {
      removeCookie(showUpgradeStripCookie);
      setUpgradeStripOpen(true);
    }
  }, [user, freeAccessCookie, showUpgradeStrip, upgradableSites, hasDisableUpgradeStripCookie, removeCookie]);

  useEffect(() => {
    if (hasUpgradableSites && showUpgradeStrip && !cookies[showUpgradeStripCookie] && user) {
      dispatch(loadUpgradableSites());
    }
  }, [hasUpgradableSites, cookies, showUpgradeStrip, user, dispatch]);

  const closeUpgradeStrip = useCallback(() => {
    const expires = new Date(new Date().getTime() + 86400000); // 24 Hrs
    setCookie(showUpgradeStripCookie, false, { path: '/', expires });
    setUpgradeStripOpen(false);
  }, [setCookie]);

  return [upgradeStripOpen, closeUpgradeStrip] as const;
};
