import { take, call, fork, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import { RESET_DURATION } from './helpers/config';

const EXPAND_COMMENTS = 'man-site/blog/EXPAND_COMMENTS';
const RESET_COMMENTS = 'man-site/blog/RESET_COMMENTS';
const RESET = 'man-site/blog/RESET';
const LOAD = 'man-site/blog/LOAD';
const LOAD_SAGA = 'man-site/blog/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/blog/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/blog/LOAD_FAIL';

const endpoint = '/api/blog';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  items: [],
  itemCount: 0,
  lastCallParams: {},
  timestamp: new Date().getTime(),
  blogCount: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        lastCallParams,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.result.blogs,
        itemCount: action.result.total,
        timestamp: new Date().getTime(),
        blogCount: action.result.counts,
      };
    case LOAD_FAIL:
      return {
        ...initialState,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case RESET:
      return {
        ...initialState,
      };
    case EXPAND_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          truncated: false,
        },
      };
    case RESET_COMMENTS:
      return {
        ...state,
        comments: initialState.comments,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams, params) {
  return isEqual(lastCallParams, params);
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.blog;
  const currentState = yield select(getState);
  const { lastCallParams } = currentState;
  const isOld = new Date().getTime() - currentState.timestamp > RESET_DURATION;
  if (
    ((!isLoaded(lastCallParams, params) || isOld) && !currentState.loading) ||
    (!currentState.loaded && !currentState.loading)
  ) {
    // reload images after a failure
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }), {
      lastCallParams: { ...params },
    });
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
