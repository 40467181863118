import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from '../../redux';
import {
  isExtendedButNotExpired,
  isExtensionRecentlyExpired,
  lessThanAWeekLeftButNotExpired,
} from 'src/client/helpers/dates';
import {
  crossSiteBannerCookie,
  showExpiringPromo,
  showGuestGatewayCookie,
  showSevenDaysOrLessPromo,
} from './PopupBanners';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { Biller } from 'src/shared/constants/billers';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';

const whitelistedEmails = ['@metart.com', '@metartnetwork.com'];

export const isPromoAvailableForSubscription = (subscription: BaseSubscription, currentSiteUUID: string) =>
  subscription.siteUUID === addDashesToUUID(currentSiteUUID) &&
  subscription.recurringPeriod === 30 &&
  subscription.recurringPrice === '29.99' &&
  [Biller.RocketGate].includes(subscription.billerID);

// Check if we have any subscriptions expiring soon, for the first time before extension
export const isSubscriptionExpiringSoon = (activeSubscriptions: ActiveSubscription[] | undefined, siteUUID: string) =>
  (activeSubscriptions || []).some((sub) => {
    // if they haven't cancelled their subscription yet or if they are extended, we skip it
    // we only care about the first time the user is expiring in this method
    if (!sub.cancelDate || sub.promoExtensionDate) {
      return false;
    }

    return isPromoAvailableForSubscription(sub, siteUUID) && lessThanAWeekLeftButNotExpired(sub.expireDate);
  });

// Check if we have subscriptions that originally expired but were extended
export const isSubscriptionExtensionExpiring = (
  activeSubscriptions: ActiveSubscription[] | undefined,
  siteUUID: string,
) =>
  (activeSubscriptions || []).some(
    (sub) =>
      isPromoAvailableForSubscription(sub, siteUUID) &&
      isExtendedButNotExpired(sub.expireDate, sub.promoExtensionDate) &&
      lessThanAWeekLeftButNotExpired(sub.expireDate),
  );

// Check if we have subscriptions that were in extension recently, but have now fully expired
export const isSubscriptionExtensionRecentlyExpired = (
  expiredSubscriptions: ExpiredSubscription[] | undefined,
  siteUUID: string,
) =>
  (expiredSubscriptions || []).some(
    (sub) =>
      isPromoAvailableForSubscription(sub, siteUUID) &&
      isExtensionRecentlyExpired(sub.expireDate, sub.promoExtensionDate),
  );

type PopupState = {
  // Hustler - Mr Skin Promo
  showCrossSiteModal: boolean;
  // Expiration Campaign
  showSevenDaysOrLessModal: boolean;
  showOnExpirationModal: boolean;
  // The MetArt User Gateway
  showGuestGateway: boolean;
};

export const usePopupBanners = () => {
  const { user } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const [cookies, setCookie] = useCookies();
  const { isPromoActive } = usePromotions();

  // Remove this when we go fully live with the promo popup
  const isFeatureTestUser = whitelistedEmails.some((domain) => user?.email.endsWith(domain));

  // Everything used in this computed state is avaliable on the first render,
  // so there is no need to use any effects to calculate this state.
  const computeInitialState = () => {
    // This is the Hustler/Mr Skin promo
    const showCrossSiteModal = Boolean(
      !cookies[crossSiteBannerCookie] &&
        config.crossSitePopupBanner &&
        window.innerWidth >= 1170 &&
        user?.validSubscription,
    );

    const showSevenDaysOrLessModal = Boolean(
      !cookies[showSevenDaysOrLessPromo] &&
        config.featureFlags?.enableExpiringPromoModal &&
        isFeatureTestUser &&
        isSubscriptionExpiringSoon(user?.subscriptions, site.UUID),
    );

    const showOnExpirationModal = Boolean(
      !cookies[showExpiringPromo] &&
        config.featureFlags?.enableExpiringPromoModal &&
        isFeatureTestUser &&
        (isSubscriptionExtensionExpiring(user?.subscriptions, site.UUID) ||
          isSubscriptionExtensionRecentlyExpired(user?.expiredSubscriptions, site.UUID)),
    );

    // This is the MetArt User Gateway
    const showGuestGateway = Boolean(
      !cookies[showGuestGatewayCookie] &&
        config.hasMemberUpgrades &&
        !isPromoActive &&
        window.innerWidth >= 1170 &&
        user &&
        !user.validSubscription,
    );

    // Find the first open modal and return the key
    // These modals are in order of priority, and only one the first one found
    // with a true value will be active.
    return Object.entries({
      showCrossSiteModal,
      showSevenDaysOrLessModal,
      showOnExpirationModal,
      showGuestGateway,
    }).find(([, value]) => value)?.[0] as keyof PopupState;
  };

  const [activeModal, setActiveModal] = useState<keyof PopupState | null>(() => computeInitialState());

  const closeModal = (cookieName: string) => () => {
    const expires = new Date(new Date().getTime() + 86400000); // 24 Hrs
    setCookie(cookieName, false, { path: '/', expires });
    setActiveModal(null);
  };

  return [activeModal, closeModal] as const;
};
