import { take, call, fork, select } from 'redux-saga/effects';
import {
  SplashBlogResponse,
  SplashFeaturedGallery,
  SplashFreeGallery,
  SplashGalleryResponse,
  SplashModelResponse,
  SplashResponse,
  SplashTopModel,
  TestimonialsResponse,
  VideoLoopResponse,
} from 'src/@types/Splash';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';

const LOAD = 'man-site/Splash/LOAD';
const LOAD_SAGA = 'man-site/Splash/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/Splash/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/Splash/LOAD_FAIL';

const endpoint = constructUrl(urls.get.splash);

const getRandomVideoLoop = (videoLoops) =>
  videoLoops.length > 0 ? videoLoops[Math.floor(Math.random() * videoLoops.length)] : null;

type InitialState = {
  blog?: SplashBlogResponse;
  freeGalleries: SplashFreeGallery[];
  featuredGallery?: SplashFeaturedGallery;
  galleryUpdates: SplashGalleryResponse[];
  loading: boolean;
  loaded: boolean;
  models: {
    topModel?: SplashTopModel;
    runnersUp: SplashModelResponse[];
  };
  movieUpdates: SplashGalleryResponse[];
  testimonials: TestimonialsResponse[];
  videoLoop: VideoLoopResponse | undefined;
};

const initialState: InitialState = {
  movieUpdates: [],
  galleryUpdates: [],
  models: {
    topModel: {} as SplashTopModel,
    runnersUp: [],
  },
  freeGalleries: [],
  loading: false,
  loaded: false,
  videoLoop: undefined,
  testimonials: [],
};

type ActionLoad = {
  type: typeof LOAD;
};

type ActionLoadSuccess = {
  type: typeof LOAD_SUCCESS;
  result: SplashResponse;
};

type ActionLoadFail = {
  type: typeof LOAD_FAIL;
};

export default function reducer(
  state = initialState,
  action: ActionLoad | ActionLoadFail | ActionLoadSuccess,
): InitialState {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_SUCCESS: {
      const videoLoop = getRandomVideoLoop(action.result.videoLoops);
      return {
        ...state,
        blog: action.result.blog,
        featuredGallery: action.result.featuredGallery,
        freeGalleries: action.result.freeGalleries,
        galleryUpdates: action.result.galleries,
        models: {
          topModel: action.result.topModel,
          runnersUp: action.result.models,
        },
        loaded: true,
        loading: false,
        movieUpdates: action.result.movies,
        testimonials: action.result.testimonials,
        videoLoop,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        blog: initialState.blog,
        freeGalleries: initialState.freeGalleries,
        galleryUpdates: initialState.galleryUpdates,
        loaded: true,
        loading: false,
        models: initialState.models,
        movieUpdates: initialState.movieUpdates,
      };
    default: {
      return state;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function load(params?: any) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

/* SAGAS */
function* loadGenerator(params) {
  const getState = (state) => state.splash;
  const currentState = yield select(getState);
  if (!currentState.loaded && !currentState.loading) {
    const loadFunc = getRequestFunc([LOAD, LOAD_SUCCESS, LOAD_FAIL], (client) => client.get(endpoint, { params }));
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(LOAD_SAGA);
    yield fork(loadGenerator, params);
  }
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
