import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { intlReducer as intl } from 'react-intl-redux';
import { reducer as form } from 'redux-form';

import activity from './activity';
import advancedSearch from './advancedSearch';
import app from './app';
import auth from './auth';
import blog from './blog';
import blogPost from './blogPost';
import cams from './cams';
import category from './category';
import comments from './comments';
import favoriteInfo from './favoriteInfo';
import favoritesAndRatings from './favoritesAndRatings';
import galleries from './galleries';
import gallery from './gallery';
import galleryPermissions from './galleryPermissions';
import image from './image';
import imageGrid from './imageGrid';
import itemCounts from './itemCounts';
import leaderboard from './leaderboard';
import markdowns from './markdowns';
import marketing from './marketing';
import model from './model';
import models from './models';
import movie from './movie';
import movies from './movies';
import newsletter from './newsletter';
import notifications from './notifications';
import photographer from './photographer';
import photographers from './photographers';
import piracyReport from './piracyReport';
import playedMovies from './playedMovies';
import potd from './potd';
import PPSInfo from './PPSInfo';
import ratingInfo from './ratingInfo';
import searchResults from './searchResults';
import settings from './settings';
import site from './site';
import siteNotifications from './siteNotifications';
import sites from './sites';
import specialFeatures from './specialFeatures';
import splash from './splash';
import tags from './tags';
import theme from './theme';
import topImages from './topImages';
import updates from './updates';
import userComments from './userComments';
import whiteLabelSplash from './whiteLabelSplash';

// Please keep alphabetized
const rootReducer = combineReducers({
  activity,
  advancedSearch,
  app,
  auth,
  blog,
  blogPost,
  cams,
  category,
  comments,
  favoriteInfo,
  favoritesAndRatings,
  form,
  galleries,
  gallery,
  galleryPermissions,
  image,
  imageGrid,
  intl,
  itemCounts,
  leaderboard,
  markdowns,
  marketing,
  model,
  models,
  movie,
  movies,
  newsletter,
  notifications,
  photographer,
  photographers,
  piracyReport,
  playedMovies,
  potd,
  PPSInfo,
  ratingInfo,
  routing,
  searchResults,
  settings,
  site,
  siteNotifications,
  sites,
  specialFeatures,
  splash,
  tags,
  theme,
  topImages,
  updates,
  userComments,
  whiteLabelSplash,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
