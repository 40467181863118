import { DESKTOP } from '@tovia/man-ui';
import React from 'react';
import styled from 'styled-components/macro';
import { MOBILE_LANDSCAPE } from 'src/client/constants/mediaQueries';

export enum ToastState {
  SEVEN_DAYS_ADDED,
  SEVEN_DAYS_EXPIRED,
}

type Props = {
  toastState: ToastState;
  cdnUrl: string;
};

export const Toast = (props: Props) => {
  return props.toastState === ToastState.SEVEN_DAYS_ADDED ? (
    <OnExpiration>
      <TextDiv>
        <img src={`${props.cdnUrl}/expirationPromo/toast/checkmark.png`} alt="7 free days added" />
        Congratulations! We have added 7 FREE days to your membership.
      </TextDiv>
    </OnExpiration>
  ) : (
    <AfterExpiration>
      <TextDiv>
        <img src={`${props.cdnUrl}/expirationPromo/toast/alert.png`} alt="7 free days expired" />
        <span>
          <b>Alert!</b> Your free 7 day membership has now expired
        </span>
      </TextDiv>
    </AfterExpiration>
  );
};

const TextDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    margin-right: 10px;
    height: 100%;
  }
`;

const OnExpiration = styled.div`
  position: absolute;
  text-align: center;
  top: -74px;
  font-size: smaller;
  border-radius: 6px;
  padding: 10px;
  background-color: #e2efda;
  color: #4a7543;

  @media ${MOBILE_LANDSCAPE} {
    top: -48px;
  }
`;

const AfterExpiration = styled.div`
  position: absolute;
  text-align: center;
  top: -74px;
  font-size: smaller;
  border-radius: 6px;
  padding: 10px;
  background: #efdfde;
  color: #803833;

  @media ${DESKTOP} {
    top: -48px;
  }
`;
