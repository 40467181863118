import React, { ReactEventHandler } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JoinButton } from 'src/client/components/buttons/JoinButton';

type Props = {
  render: boolean;
  close: () => void;
};

export const UpgradeStrip = ({ close, render }: Props) => {
  const intl = useIntl();
  if (!render) return null;

  const handleClose: ReactEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    close();
  };

  return (
    <UpgradeStripWrapper>
      <JoinButton campaign="upgrade-strip">
        <p>
          <span className="access-text">
            {intl.formatMessage({ id: 'upgradeStrip.title', defaultMessage: 'FREE ACCESS' })}
          </span>{' '}
          <span className="access-text-xs">{`- ${intl.formatMessage({
            id: 'upgradeStrip.accessText.xs',
            defaultMessage: 'Members Only!',
          })}`}</span>
          <span className="access-text-sm">
            {intl.formatMessage({
              id: 'upgradeStrip.accessText.sm',
              defaultMessage:
                'to any Metart Network site. Visit our discounts area to find great "Members Only" upgrades! ',
            })}
          </span>
          <span className="access-text-lg">
            {intl.formatMessage({
              id: 'upgradeStrip.accessText.lg',
              defaultMessage:
                'to any Metart Network site. Visit our discounts area to find great "Members Only" specials and upgrades!',
            })}
          </span>
          <FontAwesomeIcon onClick={handleClose} icon={faTimesCircle} className="upgrade-close" />
        </p>
      </JoinButton>
    </UpgradeStripWrapper>
  );
};

const UpgradeStripWrapper = styled.div`
  background-color: #f1cc1d;
  height: 28px;
  padding: 4px 0;
  text-align: center;

  a {
    color: #000;
    &:hover,
    &:active {
      color: #333;
      text-decoration: none;
    }
  }

  .access-text {
    font-weight: 700;
    text-decoration: underline;
    vertical-align: top;
  }

  .access-text-xs {
    display: none;
    vertical-align: top;

    @media (max-width: 767px) {
      display: inline;
    }
  }
  .access-text-sm {
    display: none;
    vertical-align: top;

    @media (min-width: 768px) and (max-width: 991px) {
      display: inline;
    }
  }
  .access-text-lg {
    display: none;
    vertical-align: top;

    @media (min-width: 992px) {
      display: inline;
    }
  }

  .upgrade-close {
    cursor: pointer;
    font-size: 1.3rem;
    margin-left: 8px;

    &:hover {
      color: #fff;
    }
  }
`;
