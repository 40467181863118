import { call, fork, select, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { getRequestFunc } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { Blog } from '@tovia/man-protos/dist/types/content.types';
import { RootState } from './reducer';
import { ResponseError } from 'superagent';

const ActionType = {
  LOAD_COMMENTS_FAIL: 'man-site/blogPost/LOAD_COMMENTS_FAIL',
  LOAD_COMMENTS_SAGA: 'man-site/blogPost/LOAD_COMMENTS_SAGA',
  LOAD_COMMENTS_SUCCESS: 'man-site/blogPost/LOAD_COMMENTS_SUCCESS',
  LOAD_COMMENTS: 'man-site/blogPost/LOAD_COMMENTS',
  LOAD_FAIL: 'man-site/blogPost/LOAD_FAIL',
  LOAD_SAGA: 'man-site/blogPost/LOAD_SAGA',
  LOAD_SUCCESS: 'man-site/blogPost/LOAD_SUCCESS',
  LOAD: 'man-site/blogPost/LOAD',
  RESET_COMMENTS: 'man-site/blogPost/RESET_COMMENTS',
  RESET: 'man-site/blogPost/RESET',
} as const;

const endpoint = constructUrl(urls.get.blogPost);

type ActionType = typeof ActionType[keyof typeof ActionType];

type Comment = {
  truncated: boolean;
};

type CallParams = {
  date: string;
  name: string;
};

type Node = {
  comments?: Result;
};

type Result = {
  blogs: Blog[];
  total: number;
};

type Load = {
  lastCallParams: CallParams;
  params: CallParams;
  type: typeof ActionType.LOAD;
};

type LoadSuccess = State & {
  result: Result;
  type: typeof ActionType.LOAD_SUCCESS;
};

type LoadFail = {
  error: ResponseError;
  type: typeof ActionType.LOAD_FAIL;
};

type Reset = {
  type: typeof ActionType.RESET;
};

type ResetComments = State & {
  type: typeof ActionType.RESET_COMMENTS;
};

type LoadComments = State & {
  type: typeof ActionType.LOAD_COMMENTS;
};

type LoadCommentsSuccess = State & {
  node: Node;
  result: Result;
  type: typeof ActionType.LOAD_COMMENTS_SUCCESS;
};

type LoadCommentsFailed = State & {
  type: typeof ActionType.LOAD_COMMENTS_FAIL;
};

type LoadSagaAction = {
  params: CallParams;
  type: typeof ActionType.LOAD_SAGA;
};

type LoadCommentsAction = {
  params: CallParams;
  type: typeof ActionType.LOAD_COMMENTS_SAGA;
};

type Action =
  | Load
  | LoadSuccess
  | LoadFail
  | Reset
  | ResetComments
  | LoadComments
  | LoadCommentsSuccess
  | LoadCommentsFailed;

type State = {
  comments: Comment;
  error?: ResponseError;
  item?: Blog;
  lastCallParams?: CallParams;
  loaded: boolean;
  loading: boolean;
  node?: Node;
};

const initialState: State = {
  comments: {
    truncated: true,
  },
  loaded: false,
  loading: false,
  node: {},
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD: {
      const { lastCallParams } = action;
      return {
        ...initialState,
        loading: true,
        lastCallParams,
      };
    }
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.result.blogs[0],
      };
    case ActionType.LOAD_FAIL:
      return {
        ...initialState,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case ActionType.RESET:
      return {
        ...initialState,
      };
    case ActionType.RESET_COMMENTS:
      return {
        ...state,
        comments: initialState.comments,
      };
    case ActionType.LOAD_COMMENTS: {
      return {
        ...state,
      };
    }
    case ActionType.LOAD_COMMENTS_SUCCESS:
      return {
        ...state,
        node: {
          ...state.node,
          comments: action.result,
        },
      };
    case ActionType.LOAD_COMMENTS_FAIL:
      return {
        ...state,
      };
    default: {
      return state;
    }
  }
}

export function isLoaded(lastCallParams: CallParams, params: CallParams): boolean {
  return isEqual(lastCallParams, params);
}

export function load(params: CallParams): LoadSagaAction {
  return {
    type: ActionType.LOAD_SAGA,
    params,
  };
}

export function loadComments(params): LoadCommentsAction {
  return {
    type: ActionType.LOAD_COMMENTS_SAGA,
    params,
  };
}

/* SAGAS */

// Trigger
function* loadGenerator({ params }: { type: string; params: CallParams }) {
  type CombinedType = State & {
    siteUUID: string;
    lastCallParams: CallParams;
  };
  const blogState: CombinedType = yield select((state: RootState) => ({
    ...state.blogPost,
    siteUUID: state.site.UUID,
  }));
  const { lastCallParams } = blogState;
  if ((!isLoaded(lastCallParams, params) && !blogState.loading) || (!blogState.loaded && !blogState.loading)) {
    // reload images after a failure
    const loadFunc = getRequestFunc(
      [ActionType.LOAD, ActionType.LOAD_SUCCESS, ActionType.LOAD_FAIL],
      (client) => client.get(endpoint.replace(':name', params.name).replace(':date', params.date)),
      {
        lastCallParams: { ...params },
        siteUUID: blogState.siteUUID,
      },
    );
    yield call(loadFunc);
  }
}

// Trigger
function* watchLoad() {
  yield takeEvery(ActionType.LOAD_SAGA, loadGenerator);
}

export const watchers = [fork(watchLoad)];
/* EOF SAGAS */
