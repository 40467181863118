import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalDialog } from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { useSelector } from 'src/client/redux';
import { DESKTOP, TABLET_PORTRAIT } from '@tovia/man-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { isExtendedAndExpired, isExtendedButNotExpired, isExtensionRecentlyExpired } from 'src/client/helpers/dates';
import { Toast, ToastState } from './Toast';
import { isPromoAvailableForSubscription } from 'src/client/containers/PopupBanners/usePopupBanners';
import { getCheckoutPageUrl } from 'src/client/helpers/urls/campaignUrls';

type Props = {
  show: boolean;
  onClose: () => void;
  campaignUrls: {
    expireDay: string;
    afterExpire: string;
  };
};

const getToastState = (expireDate: string | undefined, extensionDate: string | undefined) =>
  isExtendedAndExpired(expireDate, extensionDate) ? ToastState.SEVEN_DAYS_EXPIRED : ToastState.SEVEN_DAYS_ADDED;

export const ExpirationPopup = (props: Props) => {
  const { onClose, show, campaignUrls } = props;
  const { cdnUrl, joinSubdomain } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);

  const subscriptionInExtension = user?.subscriptions.find(
    (sub) =>
      isPromoAvailableForSubscription(sub, site.UUID) &&
      isExtendedButNotExpired(sub.expireDate, sub.promoExtensionDate),
  );

  const subscriptionRecentlyExpired = user?.expiredSubscriptions.find(
    (sub) =>
      isPromoAvailableForSubscription(sub, site.UUID) &&
      isExtensionRecentlyExpired(sub.expireDate, sub.promoExtensionDate),
  );

  // can be either situation, they both should be able to see this popup.
  const subscription = subscriptionInExtension || subscriptionRecentlyExpired;

  useEffect(() => {
    if (subscription && window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'overlay_impression',
        eventAction: 'stage_5',
      });
    }
  }, [subscription]);

  const overlayClick = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'overlay_click',
        eventAction: 'stage_5',
      });
    }
  };

  // shouldn't be in here if we don't have a subscription, but showing nothing just in case
  if (!subscription) {
    return null;
  }

  return (
    <StyledModal show={show} onClose={onClose} DialogComponent={StyledDialog} zIndex={2000}>
      <Toast cdnUrl={cdnUrl} toastState={getToastState(subscription.expireDate, subscription.promoExtensionDate)} />
      <BannerImage cdnUrl={cdnUrl} site={site.abbreviation} />
      <CallToAction>
        <Headline>SPECIAL OFFER</Headline>
        <DiscountImage cdnUrl={cdnUrl} />
        <StartButton
          href={getCheckoutPageUrl(
            isExtendedButNotExpired(subscription.expireDate, subscription.promoExtensionDate)
              ? campaignUrls.expireDay
              : campaignUrls.afterExpire,
            subscription,
            true,
            site.domain,
            joinSubdomain,
          )}
          onClick={overlayClick}
        >
          Start saving today
          <StyledIcon icon={faCaretRight} />
        </StartButton>
        <Hurry>HURRY - LIMITED TIME OFFER</Hurry>
        <Support>
          *Your new plan will be available at the completion of your current plan <br />
          Easily cancel at anytime by visiting our{' '}
          <a href="https://metart.atlassian.net/servicedesk/customer/portals">support page</a>
        </Support>
      </CallToAction>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 0;
  background-color: #131313;
  color: #ccc;

  @media ${DESKTOP} {
    width: 1140px;
  }

  /* This is the 'ScrollWrapper' element in the modal */
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${DESKTOP} {
      flex-direction: row;
    }
  }
`;

const StyledDialog = styled(ModalDialog)`
  border: 0;
  border-radius: 0;
  max-width: 90%;

  @media ${TABLET_PORTRAIT} {
    max-width: 600px;
  }

  @media ${DESKTOP} {
    max-width: 1140px;
  }
`;

const DiscountImage = styled.div<{
  cdnUrl: string;
}>`
  width: 270px;
  aspect-ratio: 135/28;
  margin-top: 10px;
  background-image: url(${({ cdnUrl }) => `${cdnUrl}/expirationPromo/phone/offer_wrap_iphone.png`});
  background-size: cover;

  @media ${TABLET_PORTRAIT} {
    margin-top: 20px;
    width: 390px;
    aspect-ratio: 287/48;
    background-image: url(${({ cdnUrl }) => `${cdnUrl}/expirationPromo/tablet/offer_wrap_ipad.png`});
  }

  @media ${DESKTOP} {
    margin-top: 15px;
    aspect-ratio: 212/37;
    background-image: url(${({ cdnUrl }) => `${cdnUrl}/expirationPromo/desktop/offer_wrap_desktop.png`});
  }
`;

const BannerImage = styled.div<{
  cdnUrl: string;
  site: string;
}>`
  width: 100%;
  aspect-ratio: 2/1;
  background-image: url(${({ cdnUrl, site }) => `${cdnUrl}/expirationPromo/phone/${site}_phone@2x.jpg`});
  background-size: cover;

  @media ${TABLET_PORTRAIT} {
    background-image: url(${({ cdnUrl, site }) => `${cdnUrl}/expirationPromo/tablet/${site}_tablet@2x.jpg`});
  }

  @media ${DESKTOP} {
    width: 445px;
    aspect-ratio: 445/418;
    background-image: url(${({ cdnUrl, site }) => `${cdnUrl}/expirationPromo/desktop/${site}_desktop@2x.jpg`});
  }
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
  padding-bottom: 22px;

  @media ${DESKTOP} {
    padding-top: 28px;
    flex: 1 1 auto;
  }
`;

const Headline = styled.div`
  font-size: 14px;

  @media ${TABLET_PORTRAIT} {
    font-size: 20px;
  }
`;

const StartButton = styled.a`
  background-color: ${({ theme }) => theme.primaryBtn1Bg};
  color: ${({ theme }) => theme.primaryBtn1Text};
  font-size: 20px;
  width: 90%;
  border-radius: 2px;
  height: 45px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${TABLET_PORTRAIT} {
    width: 80%;
    margin-top: 30px;
    margin-bottom: 24px;
    height: 70px;
    font-size: 36px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 0.75em;
`;

const Hurry = styled.div`
  font-size: 10px;
  text-decoration: underline;

  @media ${TABLET_PORTRAIT} {
    font-size: 14px;
  }
`;

const Support = styled.div`
  font-size: 9px;
  margin-top: 25px;
  text-align: center;

  @media ${TABLET_PORTRAIT} {
    font-size: 13px;
  }

  @media ${DESKTOP} {
    font-size: 12px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  br {
    display: none;

    @media ${DESKTOP} {
      display: inline;
    }
  }
`;
