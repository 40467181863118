import { call, cancel, fork, put, select, take } from 'redux-saga/effects';

import { ResponseError } from 'superagent';
import { getRequestFunc } from 'src/client/helpers';

import urls, { constructUrl } from 'src/shared/urls';
import { ACTIVITY_FAVORITE, ACTIVITY_FOLLOW, FOLLOW_TYPES } from 'src/shared/constants/activity';
import { ActivitySocketMessage } from 'src/@types/socket.io';
import { ActivityObjectType } from '@tovia/man-protos/dist/types/user.types';

const ADD_FAVORITES = 'man-site/favoriteInfo/ADD_FAVORITES'; // Manually add uuids
const REMOVE_FAVORITES = 'man-site/favoriteInfo/REMOVE_FAVORITES'; // Manually remove uuids
const ADD_LOADING_QUEUE = 'man-site/favoriteInfo/ADD_LOADING_QUEUE';
const LOAD = 'man-site/favoriteInfo/LOAD';
const LOAD_SAGA = 'man-site/favoriteInfo/LOAD_SAGA';
const LOAD_SUCCESS = 'man-site/favoriteInfo/LOAD_SUCCESS';
const LOAD_FAIL = 'man-site/favoriteInfo/LOAD_FAIL';

const ADD_FAVOR_SAGA = 'man-site/favoriteInfo/ADD_FAVOR_SAGA';
const ADD_FAVOR = 'man-site/favoriteInfo/ADD_FAVOR';
const ADD_FAVOR_SUCCESS = 'man-site/favoriteInfo/ADD_FAVOR_SUCCESS';
const ADD_FAVOR_FAIL = 'man-site/favoriteInfo/ADD_FAVOR_FAIL';

const REMOVE_FAVOR_SAGA = 'man-site/favoriteInfo/REMOVE_FAVOR_SAGA';
const REMOVE_FAVOR = 'man-site/favoriteInfo/REMOVE_FAVOR';
const REMOVE_FAVOR_SUCCESS = 'man-site/favoriteInfo/REMOVE_FAVOR_SUCCESS';
const REMOVE_FAVOR_FAIL = 'man-site/favoriteInfo/REMOVE_FAVOR_FAIL';

const endpoint = constructUrl(urls.post.favoriteInfo);
const addFavorEndpoint = constructUrl(urls.post.favor);
const removeFavorEndpoint = constructUrl(urls.delete.favor);

interface InitialState {
  loadingObjectUUIDs: string[];
  loadedObjectUUIDs: string[];
  loading?: boolean;
  loaded?: boolean;
  favoritingObjectUUIDs: string[];
}

export const initialState: InitialState = {
  loadingObjectUUIDs: [],
  loadedObjectUUIDs: [],
  // result: {},
  favoritingObjectUUIDs: [],
};

interface ActionAddFavorites {
  type: typeof ADD_FAVORITES;
  UUIDs: string[];
}

interface ActionRemoveFavorites {
  type: typeof REMOVE_FAVORITES;
  UUIDs: string[];
}

interface ActionAddLoadingQueue {
  type: typeof ADD_LOADING_QUEUE;
  objectUUIDs: string[];
}

interface ActionLoad {
  type: typeof LOAD;
}

interface ActionLoadSuccess {
  type: typeof LOAD_SUCCESS;
  objectUUIDs: string[];
  result: {
    items: string[];
  };
}

interface ActionLoadFail {
  type: typeof LOAD_FAIL;
  error: ResponseError;
  objectUUIDs: string[];
}

interface ActionAddOrRemoveFavorFail {
  type: typeof REMOVE_FAVOR_FAIL | typeof ADD_FAVOR;
  UUID: string;
}

interface ActionRemoveFavorOrAddFail {
  type: typeof REMOVE_FAVOR | typeof ADD_FAVOR_FAIL;
  UUID: string;
}

export default function reducer(
  state = initialState,
  action:
    | ActionAddFavorites
    | ActionRemoveFavorites
    | ActionAddLoadingQueue
    | ActionLoad
    | ActionLoadSuccess
    | ActionLoadFail
    | ActionAddOrRemoveFavorFail
    | ActionRemoveFavorOrAddFail,
): InitialState {
  switch (action.type) {
    case ADD_FAVORITES:
      return {
        ...state,
        favoritingObjectUUIDs: [...new Set([...state.favoritingObjectUUIDs, ...action.UUIDs])],
      };
    case REMOVE_FAVORITES:
      return {
        ...state,
        favoritingObjectUUIDs: [...state.favoritingObjectUUIDs.filter((UUID) => !action.UUIDs.includes(UUID))],
      };
    case ADD_LOADING_QUEUE: {
      return {
        ...state,
        loadingObjectUUIDs: [...state.loadingObjectUUIDs, ...action.objectUUIDs],
      };
    }
    case LOAD: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => !action.objectUUIDs.includes(UUID)),
        loadedObjectUUIDs: [...new Set([...state.loadedObjectUUIDs, ...action.objectUUIDs])],
        loading: false,
        loaded: true,
        favoritingObjectUUIDs: [...new Set([...state.favoritingObjectUUIDs, ...action.result.items])],
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        loadingObjectUUIDs: state.loadingObjectUUIDs.filter((UUID) => !action.objectUUIDs.includes(UUID)),
        loadedObjectUUIDs: [...new Set([...state.loadedObjectUUIDs, ...action.objectUUIDs])],
      };
    }
    case REMOVE_FAVOR_FAIL:
    case ADD_FAVOR: {
      return {
        ...state,
        favoritingObjectUUIDs: [...new Set([...state.favoritingObjectUUIDs, action.UUID])],
      };
    }
    case REMOVE_FAVOR:
    case ADD_FAVOR_FAIL: {
      return {
        ...state,
        favoritingObjectUUIDs: state.favoritingObjectUUIDs.filter((UUID) => UUID !== action.UUID),
      };
    }
    default: {
      return state;
    }
  }
}

export function addFavorites(UUIDs) {
  return {
    type: ADD_FAVORITES,
    UUIDs,
  };
}

export function removeFavorites(UUIDs) {
  return {
    type: REMOVE_FAVORITES,
    UUIDs,
  };
}

export function load(params) {
  return {
    type: LOAD_SAGA,
    params,
  };
}

export function toggleFavor(params) {
  return {
    type: ADD_FAVOR_SAGA,
    params,
  };
}

export function addFavor(params) {
  return {
    type: ADD_FAVOR_SAGA,
    params,
  };
}

export function removeFavor(params) {
  return {
    type: REMOVE_FAVOR_SAGA,
    params,
  };
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/* SAGAS */
function* loadGenerator({ params }) {
  const getState = (state) => state.favoriteInfo;
  let currentState = yield select(getState);

  const finalObjectUUIDs: string[] = [];

  params.objectUUIDs.forEach((objectUUID) => {
    if (!currentState.loadingObjectUUIDs.includes(objectUUID) && !currentState.loadedObjectUUIDs.includes(objectUUID)) {
      finalObjectUUIDs.push(objectUUID);
    }
  });

  yield put({
    type: ADD_LOADING_QUEUE,
    objectUUIDs: finalObjectUUIDs,
  });

  yield delay(1000); // the time window we want to combine all the incoming rating requests

  currentState = yield select(getState);

  if (currentState.loadingObjectUUIDs.length === 0) {
    // all the items already exists
    return;
  }

  const loadFunc = getRequestFunc(
    [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    (client) =>
      client.post(endpoint, {
        data: {
          objectUUIDs: currentState.loadingObjectUUIDs,
        },
      }),
    { objectUUIDs: currentState.loadingObjectUUIDs },
  );
  yield call(loadFunc);
}

function* addFavorGenerator(data) {
  const getCurrentState = (state) => ({
    auth: state.auth,
  });

  const currentState = yield select(getCurrentState);

  if (currentState.auth.user) {
    const loadFunc = getRequestFunc(
      [ADD_FAVOR, ADD_FAVOR_SUCCESS, ADD_FAVOR_FAIL],
      (client) =>
        client.post(addFavorEndpoint, {
          data: {
            UUID: data.objectUUID,
            type: data.objectType,
            notify: data.notify,
          },
        }),
      {
        UUID: data.objectUUID,
      },
    );
    return yield call(loadFunc);
  }

  return false;
}

function* removeFavorGenerator(data) {
  const getCurrentState = (state) => ({
    auth: state.auth,
  });

  const currentState = yield select(getCurrentState);

  if (currentState.auth.user) {
    const loadFunc = getRequestFunc(
      [REMOVE_FAVOR, REMOVE_FAVOR_SUCCESS, REMOVE_FAVOR_FAIL],
      (client) =>
        client.del(removeFavorEndpoint, {
          data: {
            UUID: data.objectUUID,
            type: data.objectType,
          },
        }),
      {
        UUID: data.objectUUID,
      },
    );
    return yield call(loadFunc);
  }

  return false;
}

// const delay = ms => new Promise(res => setTimeout(res, ms));

// Trigger
function* watchLoad() {
  let currentSaga;
  while (true) {
    // eslint-disable-line  no-constant-condition
    // strategy of taking loads only when it's not loading, but also combine what hasn't sent
    const { params } = yield take(LOAD_SAGA);
    const isLoading = yield select((state) => state.favoriteInfo.loading);
    if (!isLoading) {
      if (currentSaga) {
        yield cancel(currentSaga);
      }
      currentSaga = yield fork(loadGenerator, { params });
    }
  }
}

function* watchAddFavor() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(ADD_FAVOR_SAGA);
    const result = yield fork(addFavorGenerator, params);
    result.toPromise().then((res) => {
      // Calling the socket emit only if it's succeed
      if (res.type === ADD_FAVOR_SUCCESS && res.result.success) {
        const { objectUUID, objectType } = params;
        const message: ActivitySocketMessage = {
          actionType: FOLLOW_TYPES.includes(objectType) ? ACTIVITY_FOLLOW : ACTIVITY_FAVORITE,
          objectUUID,
          objectType:
            objectType === 'MOVIE'
              ? ActivityObjectType.GALLERY
              : ActivityObjectType[objectType as keyof typeof ActivityObjectType],
        };
        global.socket.emit('addActivity', message);
      }
    });
  }
}

function* watchRemoveFavor() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const { params } = yield take(REMOVE_FAVOR_SAGA);
    const result = yield fork(removeFavorGenerator, params);
    result.toPromise().then((res) => {
      // Calling the socket emit only if it's succeed
      if (res.type === REMOVE_FAVOR_SUCCESS && res.result.success) {
        const { objectUUID, objectType } = params;
        global.socket.emit('removeActivity', {
          actionType: FOLLOW_TYPES.includes(objectType) ? ACTIVITY_FOLLOW : ACTIVITY_FAVORITE,
          objectUUID,
        });
      }
    });
  }
}

export const watchers = [fork(watchLoad), fork(watchAddFavor), fork(watchRemoveFavor)];
/* EOF SAGAS */
