import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { EmblemButton } from '@trust/emblem-sdk-client';

import Modal from 'src/client/components/modals/Modal/Modal';
import { useSelector } from 'src/client/redux';
import { MOBILE_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/constants/mediaQueries';
import { useEmblemToken } from 'src/client/requests/useEmblemToken';
import urls, { constructUrl } from 'src/shared/urls';

export const AgeVerifyModal = () => {
  const site = useSelector((state) => state.site);
  const { cdnUrl, config, emblem, siteSubdomain } = useSelector((state) => state.app);
  const user = useSelector((state) => state.auth.user);

  const { data } = useEmblemToken(user);
  const { token } = data || {};

  const [error, setError] = useState<string | null>();

  global.socket.on('emblemAgeVerification', (error: string | null) => {
    setError(error);
  });

  return (
    <StyledModal blurredBackdrop themedModalBody onHide={() => {}} show={true}>
      <VerifyWarning>State law requires you to verify that you are 18 years or older.</VerifyWarning>
      {error ? (
        <VerifyText error>
          There was a problem during age verification.
          <br /> Please try again. If the error persists, try again later
          {config.footer.customerSupportUrl ? (
            <>
              , or{' '}
              <a target="_blank" rel="noopener noreferrer" href={config.footer.customerSupportUrl}>
                contact support
              </a>
              .
            </>
          ) : (
            '.'
          )}
        </VerifyText>
      ) : (
        <VerifyText>
          We've partnered with Emblem - a trusted age verification system.
          <br />
          It's safe, simple, and only required once.
        </VerifyText>
      )}
      <VerifyButton
        label="Verify Age"
        url={emblem.url}
        postbackHeaders={JSON.stringify({ 'Content-Type': 'application/json' })}
        postbackUrl={
          emblem.postbackUrl ||
          `https://${siteSubdomain}.${site.domain.toLowerCase()}${constructUrl(urls.post.emblem.postback)}`
        }
        projectKey={emblem.projectKey}
        state={JSON.stringify({
          networkUserUUID: user?.UUID,
          token,
        })}
        onClickCallback={() => setError(null)}
      />
      <EmblemLogo src={`${cdnUrl}/logos/emblem.svg`} alt="Emblem" />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  color: ${(props) => props.theme.primary4};

  .btn-close {
    display: none;
  }

  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    row-gap: 1.5rem;
    padding: 2rem 1rem;
    max-width: 90vw;

    @media ${MOBILE_LANDSCAPE} {
      padding: 3rem;
    }
  }
`;

const VerifyWarning = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  white-space: normal;

  @media ${TABLET_PORTRAIT} {
    white-space: nowrap;
  }
`;

const VerifyText = styled.div<{ error?: boolean }>`
  opacity: ${(props) => (props.error ? 1 : 0.7)};
  ${(props) => props.error && 'color: red'};
`;

const VerifyButton = styled(EmblemButton)`
  padding: 1rem;
  background-color: ${({ theme }) => theme.primaryBtn1Bg};
  color: #fff;
  transition: 0.3s;
  border: none;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;

  :hover,
  :focus {
    text-decoration: none;
    background: ${({ theme }) => theme.primaryBtn1HoverBg};
  }

  @media ${MOBILE_LANDSCAPE} {
    width: 50%;
  }
`;

const EmblemLogo = styled.img`
  filter: grayscale(1);
`;
