import cx from 'classnames';
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import { CameraThumbnailCard } from '../../../components/cards';
import { SideBarCamCard } from 'src/client/components/cards/CameraThumbnailCard';

type Props = {
  itemCount?: number;
  showFullCamThumbnails?: boolean;
};

export const CamsSection = (props: Props) => {
  const { cams } = useSelector((state) => state.cams);
  const { itemCount = 12 } = props;
  const detailed = useSettingsSelector('listView') === 'detailed';

  if (!cams || !cams.length) return null;

  return (
    <div className={cx('cams-wrapper', { detailed })}>
      <CamsGrid className="cams-grid">
        {cams[0].siteCams
          .slice(0, itemCount)
          .map((cam) =>
            props.showFullCamThumbnails ? (
              <SideBarCamCard
                provider={cams[0].provider}
                cam={cam}
                camTrackingPosition={'sideBar'}
                eventContentType="cam_section"
                key={cam.id}
              />
            ) : (
              <CameraThumbnailCard
                provider={cams[0].provider}
                cam={cam}
                camTrackingPosition={'sideBar'}
                eventContentType="cam_section"
                key={cam.id}
              />
            ),
          )}
      </CamsGrid>
    </div>
  );
};

const CamsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  row-gap: 20px;
  column-gap: 15px;
  height: 100%;
`;
