export default {
  delete: {
    favor: '/favor',
    rating: '/rating',
    tags: '/tags',
  },
  get: {
    accountAvailable: '/account-available',
    activity: '/activity',
    apiAgeVerificationCallback: '/age-verification-callback',
    appData: '/app-data',
    blog: '/blog',
    blogPost: '/blog-post/:date/:name',
    cams: '/cams',
    camUrl: '/camUrl',
    category: '/category',
    categories: '/categories',
    comments: '/comments',
    cover: '/cover',
    downloadMedia: '/download-media/:galleryUUID/:mediaType/:resolution',
    emblem: {
      getToken: '/emblem/get-token',
    },
    favoritesAndRatings: '/favorites-ratings',
    galleries: '/galleries',
    gallery: '/gallery',
    galleryPermissions: '/gallery-permissions',
    image: '/image',
    invoice: '/invoice',
    itemCounts: '/item-counts',
    m3u8: {
      formats: '/m3u8/:galleryUUID.m3u8',
      film: '/m3u8/:galleryUUID/:resolution.m3u8',
    },
    markdowns: '/markdowns',
    model: '/model',
    models: '/models',
    movie: '/movie',
    movies: '/movies',
    nameLetters: '/name-letters',
    notFound: '/not-found',
    photographer: '/photographer',
    photographers: '/photographers',
    potd: '/potd',
    PPS: '/PPS',
    profile: '/get-profile',
    redirectCamsUrl: '/cams/',
    redirectCamsCategoryUrl: '/cams/category/:type',
    redirectCamModelUrl: '/cams/:model/:site*?',
    redirectOldTagsUrl: '/tags/:tag',
    searchForm: '/search-form',
    searchResults: '/search-results',
    searchSuggestions: '/search-suggestions',
    siteNotifications: '/siteNotifications',
    slideshow: '/slideshow',
    specialFeatures: '/special-features',
    splash: '/splash',
    staffSelection: '/movies/staff',
    subscriptionPreview: '/subscription-preview/:token',
    tags: '/tags',
    topImages: '/top-images',
    tracking: '/tracking/:siteUUID/:type/:UUID',
    trustedPartnerSites: '/trusted-partners',
    updates: '/updates',
    upgradableSites: '/upgradable-sites',
    userData: '/user-data',
    validateDisplayName: '/validate-username/:name',
    whiteLabelSplash: '/whitelabel-splash',
  },
  post: {
    analytics: '/analytics/',
    cbpPurchase: '/cbp/gallery/:galleryUUID/:quality',
    comment: '/comment',
    emblem: {
      postback: '/emblem-postback',
    },
    favor: '/favor',
    favoriteInfo: '/favorites-info',
    freeGalleryMovieActivate: '/free-galleries-movies/activate',
    lastViewedSiteNotification: '/setLastViewedSiteNotification',
    newsletterActivate: '/newsletter/activate/:token',
    optOut: '/opt-out',
    piracyReport: '/report-piracy',
    playedMovie: '/played-movie',
    playedMoviesInfo: '/played-movies-info',
    purchase: {
      scene: '/purchase/scene',
      upgrade: '/purchase/upgrade',
    },
    rating: '/rating',
    ratingInfo: '/ratings-info',
    reportAbuse: '/report-abuse',
    signup: {
      freeGalleriesMovies: '/signup/free-galleries-movies',
      freeTrial: '/signup/free-trial',
      newsletter: '/newsletter/subscribe',
    },
    setDisplayName: '/set-displayname',
    tags: '/tags',
  },
};

export function constructUrl(str: string) {
  return `/api${str}`;
}
