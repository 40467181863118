export function modelHeadshotUrl(
  cdnUrl: string,
  item: { headshotImagePath: string; headshotImagePathSfw?: string },
  siteUUID: string,
  sfw?: boolean,
) {
  return `${cdnUrl}/${siteUUID}${
    sfw && item.headshotImagePathSfw ? item.headshotImagePathSfw : item.headshotImagePath
  }`;
}
