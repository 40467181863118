import React from 'react';
import styled from 'styled-components/macro';
import { SignupLink } from 'src/client/components/splash/Network/SignupLink';
import { Sites } from 'src/client/components/splash/Network/Sites';

type Props = {
  title: string;
  background: string;
  signup: string;
  premium: boolean;
};

const OutroWrapper = styled.div<{ backgroundImage: string; premium: boolean }>`
  min-height: 760px;
  width: 100%;
  ${(props) =>
    props.premium &&
    `
    background-image: url(${props.backgroundImage});
  `}
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    min-height: 432px;
  }
`;

const OutroTitle = styled.span<{ premium: boolean }>`
  display: block;
  color: #fff;
  font-size: 72px;
  line-height: 1.2;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  margin: 0 auto 30px;
  text-align: center;
  max-width: ${(props) => (props.premium ? '1200px' : '100%')};
  @media (max-width: 1024px) {
    font-size: 40px;
    margin-bottom: 12px;
  }
  @media (max-width: 430px) {
    font-size: 28px;
    margin-bottom: 16px;
  }
`;

const OutroMessage = styled.span`
  display: block;
  color: #fc906a;
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    font-size: 19px;
    margin-bottom: 24px;
  }
  @media (max-width: 430px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
  b {
    font-weight: 600;
  }
`;

const OutroFounded = styled.span`
  display: block;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 23px;
  text-align: left;
  margin-bottom: 50px;
  line-height: 1.5em;
  @media (max-width: 1024px) {
    line-height: 1.6em;
    font-size: 15px;
    margin-bottom: 24px;
  }
  @media (max-width: 430px) {
    font-size: 14px;
    margin-bottom: 32px;
  }
`;

const OutroAlign = styled.div`
  flex-basis: 100%;
  width: 100%;
  max-width: 1614px;
  text-align: center;
  @media (max-width: 1024px) {
    max-width: 640px;
  }
  @media (max-width: 430px) {
    max-width: 360px;
  }
`;

export const Outro = (props: Props) => {
  const { premium } = props;

  return (
    <OutroWrapper backgroundImage={props.background} premium={props.premium}>
      <OutroAlign>
        <OutroTitle premium={props.premium}>{props.title}</OutroTitle>
        {premium ? (
          <OutroMessage>
            Endless <b>Premium Sexuality</b> Of The <b>Highest Caliber</b>
          </OutroMessage>
        ) : (
          <OutroFounded>
            The MetArt Network is a world leading producer of erotic films and photography. Our reputation for pushing
            the limits of sensuality and high end production values is reflected in the countless awards we receive
            yearly across multiple niches.
            <br />
            MetArt was founded in 1999 and has grown to become one of the most well-known and respected sources for fine
            art erotica. In addition to MetArt, our flagship website, the Network includes access to 9 other premium
            sites that specialize in different niches of erotic filmmaking and photography. You can now access them
            all... including SexArt, MetArt X, Viv Thomas and The Life Erotic in one place, for one low price.
          </OutroFounded>
        )}

        <SignupLink title={props.signup} campaign="outro" />
        <Sites />
      </OutroAlign>
    </OutroWrapper>
  );
};
