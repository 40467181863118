import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';

import isArray from 'lodash/isArray';
import { modelHeadshotUrl } from 'src/client/helpers/urls';
import { cookieName as itemCookieName } from '../../RecentCookies/RecentCookiesItemInjector';
import RecentCookiesPageInjector, { cookieName as pageCookieName } from '../../RecentCookies/RecentCookiesPageInjector';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls/useGetGalleryCoverUrl';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  className?: string;
};

export const RecentsBox = (props: Props) => {
  const [cookies] = useCookies([itemCookieName, pageCookieName]);
  const intl = useIntl();
  const { contentCdnUrl } = useSelector((state) => state.app);
  const getGalleryCover = useGetGalleryCoverUrl();

  const { isOpened, onClose, className } = props;

  const recentsList = (isArray(cookies[itemCookieName]) && cookies[itemCookieName]) || [];
  const historyItems = (isArray(cookies[pageCookieName]) && cookies[pageCookieName]) || [];

  return (
    <Wrapper className={`${className} ${isOpened ? 'opened' : ''}`}>
      <CSSTransition in={isOpened} unmountOnExit classNames="recents" timeout={500}>
        <div className="container hidden-xs recents-box">
          <CloseButton onClick={onClose} className="fa-layers fa-2x">
            <ClosebuttonCircle icon={faCircle} />
            <FontAwesomeIcon icon={faTimesCircle} />
          </CloseButton>
          <h2>{intl.formatMessage({ id: 'header.recentsBox.title', defaultMessage: 'Recently Viewed' })}</h2>
          <div className="pull-left col-sm-3">
            <div className="recents-label">
              {intl.formatMessage({ id: 'header.recentsBox.pages', defaultMessage: 'Pages' })}
            </div>
            <ul className="list-unstyled">
              {historyItems.map((link, i) => (
                <li key={link.url}>
                  {i + 1}.<Link to={link.url}>{link.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="pull-left col-sm-9">
            <div className="recents-label">
              {intl.formatMessage({ id: 'header.recentsBox.content', defaultMessage: 'Content' })}
            </div>
            <ul className="list-unstyled list-inline">
              {recentsList.map((link) => (
                <li className="content-item" key={link.url}>
                  <Link to={link.url}>
                    <img
                      width="68"
                      src={
                        link.type === 'gallery'
                          ? getGalleryCover(
                              contentCdnUrl,
                              { thumbnailCoverPath: link.path, siteUUID: link.siteUUID },
                              {
                                thumbnail: true,
                              },
                            ).url
                          : modelHeadshotUrl(contentCdnUrl, { headshotImagePath: link.path }, link.siteUUID)
                      }
                      alt={link.text}
                    />
                    <span className="content-title">{link.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="clear" />
        </div>
      </CSSTransition>
      <RecentCookiesPageInjector />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 0;
  transition: padding-top 333ms linear;

  &.opened {
    padding-top: 25px;
  }

  & h2 {
    margin-top: 0;
  }

  & .recents-box {
    padding-top: 11px;
    padding-bottom: 10px;
    margin-bottom: -5px;
    position: relative;
    background: ${(props) => props.theme.primary1};
    box-shadow: 0 0 2px ${(props) => props.theme.primary3};
  }

  & .content-title {
    display: block;
  }

  & .content-item {
    width: 90px;
    vertical-align: top;
  }

  & .recents-enter {
    max-height: 0;
    overflow: hidden;
  }

  & .recents-enter.recents-enter-active {
    max-height: 85px;
    transition: max-height 500ms ease-in;
  }

  & .recents-exit {
    max-height: 85px;
  }

  & .recents-exit.recents-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms ease-in;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 900;
  transform: translate(50%, -50%);
`;

const ClosebuttonCircle = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.primary2};
`;
