import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import Panel from '@tovia/man-ui/lib/components/Panel/Panel';
import { useIsMobile } from 'src/client/utils/useIsMobile';
import { useSelector } from 'src/client/redux';
import { UserTypes } from 'src/shared/constants/userTypes';
import { Tag as TagType } from '@tovia/man-protos/dist/types/content.types';

type Props = {
  onAddNewTag: () => void;
  tags: TagType[];
  userTags?: TagType[];
};

export const TagsPanel = (props: Props) => {
  const { onAddNewTag, tags, userTags = [] } = props;
  const { userType } = useSelector((state) => state.auth);

  const isMobile = useIsMobile();
  const tagsRef = useRef<HTMLDivElement | null>(null);

  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isGuest = userType === UserTypes.Guest;
  const showButton = (isGuest && isMobile && !isExpanded && isOverflowing) || (!isGuest && !!onAddNewTag);
  const showFullList = isGuest && (!isMobile || isExpanded);

  useEffect(() => {
    if (!tagsRef.current || !isGuest || !isMobile || showFullList) return;

    const { clientWidth, scrollWidth } = tagsRef.current;

    const onResizeOverflowCheck = () => {
      const hasOverflow = scrollWidth > clientWidth;
      if (hasOverflow !== isOverflowing) {
        setIsOverflowing(hasOverflow);
      }
    };

    onResizeOverflowCheck();
    window.addEventListener('resize', onResizeOverflowCheck);

    return () => {
      window.removeEventListener('resize', onResizeOverflowCheck);
    };
  }, [isGuest, isMobile, isOverflowing, showFullList]);

  const determineTagUrl = useCallback(({ name, url }: TagType) => {
    const urlFriendlyName = (url || name).trim().replace(/\s/g, '+');

    return `/tags/${urlFriendlyName}`;
  }, []);

  const onTagsButtonClick = () => {
    if (isGuest) {
      setIsExpanded(true);
    } else {
      onAddNewTag();
    }
  };

  if (tags.length === 0) return null;

  return (
    <Panel className="col-md-12">
      <TagsContainer showFullList={showFullList} ref={tagsRef}>
        <TagsLabel>Tags:</TagsLabel>
        {tags.map((tag) => (
          <Tag key={tag.UUID} $isUserTag={userTags.includes(tag)} to={determineTagUrl(tag)}>
            {tag.name}
          </Tag>
        ))}
        {showButton && (
          <ButtonWrapperWithGradient>
            <TagsButton type="button" className="btn btn-secondary" onClick={onTagsButtonClick}>
              {isGuest ? 'View all tags' : 'Add / View all tags'}
            </TagsButton>
          </ButtonWrapperWithGradient>
        )}
      </TagsContainer>
    </Panel>
  );
};

const TagsContainer = styled.div<{ showFullList: boolean }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: ${(props) => (props.showFullList ? 'wrap' : 'nowrap')};
  gap: 0.35rem;
`;

const TagsLabel = styled.div`
  line-height: 25px;
  font-size: 0.85rem;
`;

const Tag = styled(NavLink)<{ $isUserTag: boolean }>`
  height: 25px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  font-size: 0.85rem;
  border-radius: 2px;
  margin: 2px;
  background: ${(props) => props.theme.primary3};

  ${(props) =>
    props.$isUserTag &&
    css`
      color: #fff;
      background-color: ${(props) => props.theme.primaryBtn1Bg};
      pointer-events: none;
    `}

  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
  }
`;

const ButtonWrapperWithGradient = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  right: 0;

  ::before {
    content: '';
    width: 125%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.0001) 0%,
      ${(props) => props.theme.primary1} 10%,
      ${(props) => props.theme.primary1} 100%
    );
  }
`;

const TagsButton = styled.button`
  position: relative;
  font-size: 0.72rem;
  padding: 0 5px;
  height: 18px;
  border-style: solid;
  border-width: 1px;
  text-decoration: none;
  margin-left: 6px;
`;
