import styled from 'styled-components';
import Image from '@tovia/man-ui/lib/components/Image/Image';
import { css } from 'styled-components/macro';

export const BlurredImageStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(15px);
  mask-image: radial-gradient(ellipse at center, black 0%, black 60%, transparent 100%);
  z-index: 1;
  pointer-events: none;
`;

export const BlurredImage = styled(Image)`
  ${BlurredImageStyle}
`;
