import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { Tab, Tabs } from '@tovia/man-ui';
import { ContentHeader } from 'src/client/components/ui/ContentHeader';
import { Link } from 'react-router-dom';
import LoaderWrapper from 'src/client/components/Loader/LoaderWrapper';
import GalleriesContent from '../Galleries/GalleriesContent/GalleriesContent';
import { load as loadModels, reset } from 'src/client/redux/modules/model';
import { parseSlug } from 'src/client/utils/converters';
import { ModelPanels } from '../Panels/ModelPanels';
import { NetworkGalleriesSlider } from 'src/client/components/NetworkGalleriesSlider';
import RecentCookiesItemInjector from '../RecentCookies/RecentCookiesItemInjector';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import Helmet from '../Helmet/Helmet';

import { isPrerender } from 'src/client/utils/prerenderDetect';
import { Tab as TabType, use404Redirect, useTabs } from 'src/client/helpers';
import { getGallerySortTypes, getMovieSortTypes, modelsAndArtistsRoutes, routes } from 'src/client/utils/router';
import { ModelLatest } from './ModelLatest';
import { MessageModal } from './MessageModal';
import { isSeoModifiedModel } from 'src/client/containers/Model/seoModifiedModels';
import { CommentSection } from '../CommentSection/CommentSection';
import { WideModelPage } from './WideModelPage';
import { IconOnlySortAndViewDropdown } from 'src/client/components/dropdowns';
import { SORT_TYPES as SORT_TYPES_GALLERIES } from 'src/shared/constants/galleries';
import { SORT_TYPES as SORT_TYPES_FILMS } from 'src/shared/constants/movies';
import { Settings } from 'react-slick';
import { WideModelWrapper } from 'src/client/containers/Model/WideModelPage/styles';
import { useWidePageContext } from '../App/WidePageContextProvider';
import { MODEL_GALLERIES_PER_PAGE } from 'src/shared/constants/models';

const {
  modelMovies: modelMoviesRoute,
  modelLatest: modelLatestRoute,
  modelPhotos: modelPhotosRoute,
} = routes.modelsAndArtists.routes;

const makeGold = (ref: HTMLAnchorElement | null) => ref?.style?.setProperty('color', '#CEAB58', 'important');

type RouteParams = {
  modelName: string;
  page?: string;
  tab: string;
  sortBy?: string;
};

const sortKeyByTab = {
  movies: 'filmsSorting',
  photos: 'galleriesSorting',
};

const sortTypes = {
  photos: SORT_TYPES_GALLERIES,
  movies: SORT_TYPES_FILMS,
};

export default function Model() {
  const { cdnUrl, pixelApiUrl, config } = useSelector((state) => state.app);
  const { instagram, modelPageTabs } = config;

  const site = useSelector((state) => state.site);
  const { error, item, loading } = useSelector((state) => state.model);
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();
  const widePage = useWidePageContext();

  const sortByGalleries = useSettingsSelector('galleriesSorting');
  const sortByFilms = useSettingsSelector('filmsSorting');

  const [open, setOpen] = useState(false);
  const onHide = useCallback(() => setOpen(false), []);
  const onShow = useCallback(() => {
    setOpen(true);

    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'model-video-message',
      eventLabel: 'interest-dialog',
      eventAction: 'show',
    });
  }, []);

  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const { modelName, page = 1 } = params;

  useEffect(() => {
    if (!isPrerender && item) {
      window.dataLayer.push({
        event: 'select_content',
        content_type: 'model',
        item_id: item.name,
      });
    }
  }, [item]);

  use404Redirect(error);

  const after = (Number(page) - 1) * MODEL_GALLERIES_PER_PAGE;

  const loadData = useCallback(() => {
    dispatch(loadModels({ name: parseSlug(modelName), sortBy: 'latest', after }));
  }, [dispatch, modelName, after]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add('show-jsd'); // show jsd plugin

    return () => document.body.classList.remove('show-jsd');
  }, []);

  const itemName = item?.name || '';
  const modelTabs: TabType[] = [
    {
      value: 'latest',
      to: `/model/${modelName}/latest`,
      name: intl.formatMessage({ id: 'contentHeader.model.latest' }),
      // @SEO_CLEANUP
      headerTitle: isSeoModifiedModel(item?.UUID, site.abbreviation)
        ? `${itemName} Nude Photos and Videos`
        : `Latest Photos and Films with ${itemName}`,
    },
  ];

  const sortBy = params.sortBy || 'top';
  if (Number(item?.moviesCount) > 0) {
    const sort = getMovieSortTypes(config).find((cSort) => cSort.id === sortBy)?.title || '';

    modelTabs.push({
      value: 'movies',
      to: `/model/${modelName}/movies/${sortByFilms || 'top'}`,
      name: intl.formatMessage({ id: 'contentHeader.model.movies' }),
      headerTitle: intl.formatMessage({ id: 'model.filmsWith' }, { itemName, sort }),
    });
  }

  if (Number(item?.galleriesCount) > 0) {
    const sort = getGallerySortTypes(config).find((cSort) => cSort.id === sortBy)?.title || '';

    modelTabs.push({
      value: 'photos',
      to: `/model/${modelName}/photos/${sortByGalleries || 'top'}`,
      name: intl.formatMessage({ id: 'contentHeader.model.photos' }),
      headerTitle: intl.formatMessage({ id: 'model.photosWith' }, { itemName, sort }),
    });
  }

  const [tabs, activeTab] = useTabs('models', modelTabs, modelsAndArtistsRoutes);

  const renderContentHeader = useCallback(
    ({ collapsePadding = false, showViewOptionsInTabs = false } = {}) => {
      if (!activeTab) {
        return null;
      }

      const tabComponents = tabs.map((tab) => (
        <Tab key={tab.value} as={Link} active={activeTab.value === tab.value} {...tab} />
      ));

      if (modelPageTabs.includes('model-message') && user) {
        tabComponents.push(
          <a style={{ color: '#CEAB58' }} className="tab" onClick={onShow} ref={makeGold}>
            <FormattedMessage
              id="model.personalVideo"
              values={{ name: item?.name || '' }}
              defaultMessage={`Get a personal video message from {name}`}
            />
          </a>,
        );
      }

      if (showViewOptionsInTabs) {
        tabComponents.push(
          <IconOnlySortAndViewDropdown
            key="options"
            sortSettingsKey={sortKeyByTab[activeTab.value]}
            sortTypes={sortTypes[activeTab.value]}
            pageType="model"
            modelName={modelName}
            tab={activeTab.value}
          />,
        );
      }

      const tabsNode = <StyledTabs>{tabComponents}</StyledTabs>;

      return <StyledContentHeader collapsePadding={collapsePadding} tabs={tabsNode} title={activeTab.headerTitle} />;
    },
    [activeTab, item, modelPageTabs, tabs, user, onShow, modelName],
  );

  const metaData = useMemo(() => {
    if (!item) {
      return {};
    }

    const { name, moviesCount, galleriesCount, publishAge, eyes, breasts, hair, debutMonth, debutYear } = item;

    return {
      debutMonth,
      debutYear,
      modelName: name,
      hairColor: hair,
      setsCount: moviesCount + galleriesCount,
      debutAge: publishAge,
      eyeColor: eyes,
      boobSize: breasts,
      siteName: site.name,
      domainName: site.domain,
    };
  }, [site, item]);

  if (!item) {
    return widePage ? (
      <WideModelWrapper>
        <LoaderWrapper
          defaultHeight
          reloadFunction={loadData}
          loadingStatus={loading}
          reduxErrorStatus={error}
          contentType="model"
        />
      </WideModelWrapper>
    ) : (
      <div className="content container">
        <LoaderWrapper
          defaultHeight
          reloadFunction={loadData}
          loadingStatus={loading}
          reduxErrorStatus={error}
          contentType="model"
        />
      </div>
    );
  }

  const { UUID, globalContent, name } = item;

  const modelPanels = <StyledModelPanels model={item} isModelPage />;

  const commentSection = (
    <CommentSection objectUUID={UUID} parentItem={item} parentItemType="model" campaign="model-comment" />
  );

  const wideModeReactSlickSettings: Settings = {
    slidesToScroll: 8,
    slidesToShow: 8,
    responsive: [
      { breakpoint: 576, settings: { slidesToScroll: 2, slidesToShow: 2 } },
      { breakpoint: 1200, settings: { slidesToScroll: 3, slidesToShow: 3 } },
      { breakpoint: 1600, settings: { slidesToScroll: 4, slidesToShow: 4 } },
      { breakpoint: 1800, settings: { slidesToScroll: 5, slidesToShow: 5 } },
      { breakpoint: 2200, settings: { slidesToScroll: 6, slidesToShow: 6 } },
      { breakpoint: 2400, settings: { slidesToScroll: 7, slidesToShow: 7 } },
    ],
  };

  const networkGalleriesSlider = (
    <NetworkGalleriesSlider
      galleries={globalContent}
      title={`More of ${name} in MetArt Network`}
      reactSlickSettings={widePage ? wideModeReactSlickSettings : undefined}
    />
  );

  const renderModelRoutes = () => (
    <Switch>
      <Route
        path={modelMoviesRoute.path}
        exact
        render={() => (
          <GalleriesContent sortSettingsKey="filmsSorting" UUID={UUID} galleryType="MOVIE" pageType="model" />
        )}
      />
      <Route
        path={modelPhotosRoute.path}
        exact
        render={() => <GalleriesContent UUID={UUID} galleryType="GALLERY" pageType="model" />}
      />
      <Route path={modelLatestRoute.path} exact render={() => <ModelLatest item={item} loadData={loadData} />} />
      <Redirect to={modelLatestRoute.path as string} />
    </Switch>
  );

  const recentCookiesItem = (
    <RecentCookiesItemInjector
      type="model"
      path={item.path}
      name={item.name}
      siteUUID={item.siteUUID}
      headshotImagePath={item.headshotImagePath}
    />
  );

  const messageModal = (
    <MessageModal cdnUrl={cdnUrl} instagram={instagram} onHide={onHide} show={open} siteName={site.name} />
  );

  if (widePage) {
    return (
      <>
        <Helmet id="model" defaultTitle="{{modelName}} - {{siteName}}" variables={metaData} />
        <WideModelPage model={item}>
          {renderContentHeader({ collapsePadding: true, showViewOptionsInTabs: true })}
          {renderModelRoutes()}
          {networkGalleriesSlider}
          {commentSection}
          {recentCookiesItem}
          {messageModal}
        </WideModelPage>
      </>
    );
  }

  return (
    <>
      <Helmet id="model" defaultTitle="{{modelName}} - {{siteName}}" variables={metaData} />
      <div className="content container">
        <img src={`${pixelApiUrl}/${item.siteUUID}/MODEL/${item.UUID}`} style={{ display: 'none' }} alt="pixel" />
        {modelPanels}
        {renderContentHeader()}
        {renderModelRoutes()}
        {commentSection}
        {networkGalleriesSlider}
        {recentCookiesItem}
        {messageModal}
      </div>
    </>
  );
}

const StyledModelPanels = styled(ModelPanels)`
  margin-top: 20px;
`;

const StyledContentHeader = styled(ContentHeader)<{ collapsePadding?: boolean }>`
  padding-top: ${(props) => (props.collapsePadding ? '0' : null)};
`;

const StyledTabs = styled(Tabs)`
  display: flex;
  padding-right: 5px;
  align-items: center;

  .tab {
    padding: 10px 15px;
  }
`;
