import { useQuery } from '@tanstack/react-query';
import { ResponseError } from 'superagent';
import { ApiClient } from 'src/client/helpers';
import urls, { constructUrl } from 'src/shared/urls';
import { User } from 'src/@types/app';

const emblemTokenFetcher = () => new ApiClient().get(constructUrl(urls.get.emblem.getToken)).then((res) => res.body);
export const useEmblemToken = (user?: User) =>
  useQuery<{ token: string }, ResponseError>({
    queryKey: ['emblemToken', user?.UUID],
    queryFn: emblemTokenFetcher,
    enabled: !!user?.accountNeedsAgeVerification,
    refetchOnWindowFocus: false,
  });
