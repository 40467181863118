import moment from 'moment';

export const calculateDaysRemainingFromDate = (subscriptionEndDate: string) => {
  const endDate = moment(subscriptionEndDate);
  const now = moment();

  // using `true` to get a decimal number for closer accuracy on days left. otherwise, less than a day left would always be 0
  return endDate.diff(now, 'days', true);
};

export const lessThanAWeekLeftButNotExpired = (subscriptionEndDate: string) => {
  const daysRemaining = calculateDaysRemainingFromDate(subscriptionEndDate);
  return daysRemaining > 0 && daysRemaining <= 7;
};

const isExtended = (extensionDate: string | undefined) => !!extensionDate;

const isExpired = (expireDate: string | undefined) => {
  if (!expireDate) {
    return false;
  }

  const daysRemaining = calculateDaysRemainingFromDate(expireDate);

  // looking for a negative value here since that indicates the subscription has expired
  return daysRemaining <= 0;
};

export const isExtendedButNotExpired = (expireDate: string | undefined, extensionDate: string | undefined) =>
  isExtended(extensionDate) && !isExpired(expireDate);

export const isExtendedAndExpired = (expireDate: string | undefined, extensionDate: string | undefined) =>
  isExtended(extensionDate) && isExpired(expireDate);

export const isExtensionRecentlyExpired = (expireDate: string | undefined, extensionDate: string | undefined) => {
  if (!isExtended(extensionDate) || !expireDate) {
    return false;
  }

  const daysRemaining = calculateDaysRemainingFromDate(expireDate);

  // negative values indicate the extension date is expired
  return daysRemaining >= -7 && daysRemaining <= 0;
};
