export const ADULT_CONTENT_COOKIE = 'isAdult';
export const ANALYTICS_COOKIE_NAME = 'auuid';
export const CSRF_TOKEN = '_csrfToken';
export const MAM_COOKIE_NAME = 'mam';
export const OPTANON_CONSENT_COOKIE = 'OptanonConsent';
export const OPTANON_CONSENT_CLOSED_COOKIE = 'OptanonAlertBoxClosed';
export const PARTNER_PROMO_BANNERS_COOKIE = 'showPartnerBanners';
export const PREVIEW_TRACKER_COOKIE = 'movieSlideshowSeen';
export const HIDE_FREE_SITES_BUTTON = 'hideFreeSitesButton';

export const getAffiliateCookies = (siteAbbreviation?: string) => [
  MAM_COOKIE_NAME,
  'mamident',
  'ata_SID',
  `${siteAbbreviation}_PA`,
];
