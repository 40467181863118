import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { Gallery } from 'src/client/components/splash/Network/Gallery';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { SplashGalleryResponse } from 'src/@types/Splash';

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: -15px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  .GALLERY {
    max-width: 19%;

    @media (max-width: 1600px) {
      max-width: 24%;
    }

    @media (max-width: 768px) {
      max-width: 32%;
    }

    @media (max-width: 430px) {
      max-width: 95%;
    }
  }

  .MOVIE {
    max-width: 32%;
    @media (max-width: 768px) {
      max-width: 48%;
    }

    @media (max-width: 430px) {
      max-width: 95%;
    }
  }
`;

type Props = {
  updates: SplashGalleryResponse[];
  updatesPrefix: string;
  updatesPrefixHover: string;
  updatesMax: number;
};

export const GalleryUpdates = (props: Props) => {
  const intl = useIntl();
  const { contentCdnUrl } = useSelector((state) => state.app);

  const { updates, updatesPrefix, updatesPrefixHover, updatesMax } = props;

  const sites = useSelector((state) =>
    state.sites.items.reduce((key, site) => {
      key[site.UUID] = site.name;
      return key;
    }, {}),
  );

  return (
    <Div>
      {updates &&
        updates.slice(0, updatesMax).map((gallery) => {
          const { type, name, publishedAt, models, path, imageCount, runtime, siteUUID } = gallery;

          const galleryCoverUrlString = `${contentCdnUrl}/${gallery.siteUUID}/media/${gallery.UUID}/${updatesPrefix}_${gallery.UUID}.jpg`;
          const galleryCoverUrlStringHover = `${contentCdnUrl}/${gallery.siteUUID}/media/${gallery.UUID}/${updatesPrefixHover}_${gallery.UUID}.jpg`;
          const galleryVideoUrlString = `${contentCdnUrl}/${gallery.siteUUID}/media/${gallery.UUID}/tease_preview_${gallery.UUID}.mp4`;

          return (
            <Gallery
              type={type}
              key={gallery.UUID}
              galleryUrl={path}
              publishedAt={intl.formatDate(new Date(publishedAt), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })}
              title={name}
              models={models.map((model) => model.name).join(', ')}
              galleryCoverUrl={galleryCoverUrlString}
              galleryCoverUrlHover={galleryCoverUrlStringHover}
              galleryVideoUrl={galleryVideoUrlString}
              imageCount={imageCount}
              runtime={runtime}
              gallerySite={sites[siteUUID]}
            />
          );
        })}
    </Div>
  );
};
