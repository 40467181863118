import { faSpiderBlackWidow, faUndo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import cx from 'classnames';
import { debugFormTypes, DebugValues } from 'src/@types/DebugValues';
import styled from 'styled-components/macro';
import { RootState } from 'src/client/redux/modules/reducer';
import { getEntries } from 'src/client/utils/type-utils';

type Props = {
  initialState: RootState;
  cookiedDebugValues: DebugValues;
  saveCookie: (state: DebugValues) => void;
};

export const DebugSettingsManager = (props: Props) => {
  const { cookiedDebugValues } = props;
  const [popupVisible, setPopupVisible] = React.useState(false);

  const [debugState, setDebugState] = React.useState(cookiedDebugValues);

  const saveValues = (e) => {
    e.preventDefault();
    props.saveCookie(debugState);
  };

  const setValue = (path: string) => (value: string | boolean) => {
    setDebugState({ ...debugState, [path]: value });
  };

  const clearValue = (path: keyof DebugValues) => () => {
    const { [path]: _, ...rest } = debugState;
    setDebugState(rest);
  };

  const resetAll = () => {
    setDebugState({});
  };

  return (
    <>
      <DebugClicker onClick={() => setPopupVisible(!popupVisible)} hidden={popupVisible}>
        <FontAwesomeIcon icon={faSpiderBlackWidow} />
      </DebugClicker>

      <DebugForm hidden={!popupVisible} onSubmit={saveValues}>
        <Line />
        {getEntries(debugFormTypes).map(([path, type]) => {
          const FormComponent = formComponentsMap[type] as React.FC<FormItemProps>;

          return (
            <Fragment key={path}>
              <FormComponent
                path={path}
                type={type}
                value={debugState[path]}
                clearValue={clearValue(path)}
                setValue={setValue(path)}
              />
              <Line />
            </Fragment>
          );
        })}
        <Navbar>
          <Button type="button" className="cancel" onClick={() => setPopupVisible(false)}>
            Close
          </Button>
          <Button type="button" className="reset" onClick={() => resetAll()}>
            Reset All
          </Button>
          <Button type="button" className="reload" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button type="submit">Save</Button>
        </Navbar>
      </DebugForm>
    </>
  );
};

type FormItemProps<T = string | boolean> = {
  path: string;
  clearValue: (fp) => void;
  type: string;
  value?: T;
  setValue: (value: T) => void;
};

const DebugFormCheckbox = (props: FormItemProps<boolean>) => {
  const { path, value = false } = props;

  return (
    <>
      <label className="full" htmlFor={path}>
        {path}
        <input name={path} type={props.type} checked={value} onChange={(e) => props.setValue(e.target.checked)} />
      </label>
      <FontAwesomeIcon icon={faUndo} onClick={props.clearValue} className={cx({ active: value })} />
    </>
  );
};

const DebugFormTextbox = (props: FormItemProps<string>) => {
  const { path, value = '' } = props;

  return (
    <>
      <label htmlFor={path}>{path}</label>
      <input name={path} type={props.type} value={value} onChange={(e) => props.setValue(e.target.value)} />
      <FontAwesomeIcon icon={faUndo} onClick={props.clearValue} className={cx({ active: value })} />
    </>
  );
};

const formComponentsMap: {
  checkbox: React.FC<FormItemProps<boolean>>;
  text: React.FC<FormItemProps<string>>;
} = {
  checkbox: DebugFormCheckbox,
  text: DebugFormTextbox,
};

const DebugClicker = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 2000;
  font-size: 30px;
  line-height: 1;
  color: lightpink;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;

  && svg {
    display: block;
    width: 30px;
  }
`;

const DebugForm = styled.form`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  margin: 0;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.9);
  display: ${({ hidden }) => (hidden ? 'none' : 'grid')};
  grid-template-columns: 400px 150px 25px;

  svg {
    align-self: center;
    justify-self: center;
    color: red;
    opacity: 0.2;

    &.active {
      opacity: 1;
    }
  }

  input,
  label {
    display: block;
    margin: 0;
    padding: 5px 0;
  }

  input {
    justify-self: right;

    &[type='text'] {
      background: rgba(200, 200, 200, 0.5);
      padding: 0 10px;
      width: 150px;
      border: none;
      text-align: right;
    }

    &[type='checkbox'] {
      margin-left: 1em;
    }
  }

  label {
    &.full {
      grid-column: 1 / 3;
      display: flex;
      justify-content: space-between;
    }
  }
`;

const Line = styled.hr`
  grid-column: 1 / 4;
  border: none;
  margin: 0;
  border-bottom: 0.5px solid black;
`;

const Button = styled.button`
  &.cancel {
    margin-right: 10px;
  }

  &.reload {
    margin-left: auto;
    margin-right: 10px;
  }
`;

const Navbar = styled.nav`
  margin-top: 20px;
  grid-column: 1 / 4;
  display: flex;
  flex-direction: row;
`;
