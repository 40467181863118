import React, { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import Modal, { ModalDialog } from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { useSelector } from 'src/client/redux';
import { DESKTOP, TABLET_PORTRAIT } from '@tovia/man-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { calculateDaysRemainingFromDate } from 'src/client/helpers/dates';
import { getCheckoutPageUrl } from 'src/client/helpers/urls/campaignUrls';
import { isSubscriptionExpiringSoon } from 'src/client/containers/PopupBanners/usePopupBanners';

type Props = {
  show: boolean;
  onClose: () => void;
  campaignUrl: string;
};

export const SevenDaysOrLessPopup = (props: Props) => {
  const { onClose, show, campaignUrl } = props;
  const { cdnUrl, joinSubdomain } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);

  const nearlyExpiredSubscription = user?.subscriptions.find((sub) => isSubscriptionExpiringSoon([sub], site.UUID));

  useEffect(() => {
    if (nearlyExpiredSubscription && window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'overlay_impression',
        eventAction: 'stage_4',
      });
    }
  }, [nearlyExpiredSubscription]);

  const overlayClick = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'overlay_click',
        eventAction: 'stage_4',
      });
    }
  };

  // This subscription is required to have the component load, but that's
  // decided up the tree, so we just appease typescript here pretending
  // some parts might not exist.
  if (!nearlyExpiredSubscription) {
    return null;
  }

  const daysRemaining = nearlyExpiredSubscription
    ? Math.ceil(calculateDaysRemainingFromDate(nearlyExpiredSubscription.expireDate))
    : 0;

  return (
    <StyledModal show={show} onClose={onClose} DialogComponent={StyledDialog} zIndex={2000}>
      <BannerImage cdnUrl={cdnUrl} site={site.abbreviation} />
      <CallToAction>
        <Headline>
          Your {site.domain} membership will
          <Highlight>expire in just {daysRemaining} days.</Highlight>
        </Headline>
        <Renew>Renew it now and receive</Renew>
        <DiscountImage src={`${cdnUrl}/expirationPromo/25_percent_off.svg`} />
        <StartButton
          href={getCheckoutPageUrl(campaignUrl, nearlyExpiredSubscription, false, site.domain, joinSubdomain)}
          onClick={overlayClick}
        >
          Start saving today
          <StyledIcon icon={faCaretRight} />
        </StartButton>
        <Hurry>HURRY - LIMITED TIME OFFER</Hurry>
        <Support>
          *Your new plan will be available at the completion of your current plan <br />
          Easily cancel at anytime by visiting our{' '}
          <a href="https://metart.atlassian.net/servicedesk/customer/portals">support page</a>
        </Support>
      </CallToAction>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 0;
  border-radius: 0;
  background-color: #131313;
  color: #ccc;

  @media ${DESKTOP} {
    width: 1140px;
  }

  /* This is the scroll wrapper in the modal */
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media ${DESKTOP} {
      flex-direction: row;
    }
  }
`;

const StyledDialog = styled(ModalDialog)`
  border: 0;
  border-radius: 0;
  max-width: 280px;

  @media ${TABLET_PORTRAIT} {
    max-width: 600px;
  }

  @media ${DESKTOP} {
    max-width: 1140px;
  }
`;

const BannerImage = styled.div<{
  cdnUrl: string;
  site: string;
}>`
  width: 100%;
  aspect-ratio: 280/140;
  background-image: url(${({ cdnUrl, site }) => `${cdnUrl}/expirationPromo/phone/${site}_phone@2x.jpg`});
  background-size: cover;

  @media ${TABLET_PORTRAIT} {
    aspect-ratio: 600/300;
    background-image: url(${({ cdnUrl, site }) => `${cdnUrl}/expirationPromo/tablet/${site}_tablet@2x.jpg`});
  }

  @media ${DESKTOP} {
    width: 445px;
    aspect-ratio: 445/418;
    background-image: url(${({ cdnUrl, site }) => `${cdnUrl}/expirationPromo/desktop/${site}_desktop@2x.jpg`});
  }
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;
  padding-bottom: 22px;

  @media ${DESKTOP} {
    padding-top: 28px;
    flex: 1 1 auto;
  }
`;

const textSize = css`
  font-size: 14px;

  @media ${TABLET_PORTRAIT} {
    font-size: 20px;
  }
`;

const Headline = styled.div`
  ${textSize}
`;

const Highlight = styled.div`
  color: red;
  text-align: center;

  @media ${TABLET_PORTRAIT} {
    display: inline;
    margin-left: 0.25em;
  }
`;

const Renew = styled.div`
  margin-top: 5px;
  ${textSize}
`;

const DiscountImage = styled.img`
  width: 200px;
  margin-top: 10px;

  @media ${TABLET_PORTRAIT} {
    margin-top: 20px;
    width: 390px;
  }

  @media ${DESKTOP} {
    margin-top: 15px;
  }
`;

const StartButton = styled.a`
  background-color: ${({ theme }) => theme.primaryBtn1Bg};
  color: ${({ theme }) => theme.primaryBtn1Text};
  font-size: 20px;
  width: 90%;
  border-radius: 2px;
  height: 45px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${TABLET_PORTRAIT} {
    width: 80%;
    margin-top: 30px;
    margin-bottom: 24px;
    height: 70px;
    font-size: 36px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 0.75em;
`;

const Hurry = styled.div`
  font-size: 10px;

  @media ${TABLET_PORTRAIT} {
    font-size: 14px;
  }
`;

const Support = styled.div`
  font-size: 9px;
  margin-top: 25px;
  max-width: 90%;
  text-align: center;

  @media ${TABLET_PORTRAIT} {
    font-size: 13px;
  }

  @media ${DESKTOP} {
    font-size: 12px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  br {
    display: none;

    @media ${DESKTOP} {
      display: inline;
    }
  }
`;
