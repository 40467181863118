import React, { useEffect, useState } from 'react';
import { PromoFooterBanner } from 'src/client/containers/Promo/PromoFooterBanner';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import { usePromoPopup } from 'src/client/containers/AdsZones/usePromoPopup';
import { usePromotions } from 'src/client/helpers/usePromotions';
import { PromoPopup } from 'src/client/containers/Promo/PromoPopup';
import { useSelector } from 'src/client/redux';
import { GatewayModal } from 'src/client/containers/PopupBanners/GatewayModal';

const showGatewayCookie = 'showFreeAccess';
const ONE_DAY = 1000 * 60 * 60 * 24;
const TEN_DAYS = ONE_DAY * 10;

// TODO: probably need to combine all the logic for the popup banners since they are spread across too many files
export function PopupsManager() {
  const { user, loaded: userLoaded } = useSelector((state) => state.auth);
  const { upgradableSites } = useSelector((state) => state.marketing);
  const [cookies, setCookie] = useCookies([showGatewayCookie]);
  const location = useLocation();
  const { isPromoActive, isPromoFooterActive, isPromoPopupActive, popupDisabledPaths } = usePromotions();

  const isPopupAllowedOnPage = !popupDisabledPaths.includes(location.pathname.replace(/\/$/, ''));

  // if promo popup isn't enabled we want to initialize this to dismissed to possibly show the footer
  const [dismissedPopup, setDismissedPopup] = useState<boolean>(!isPromoPopupActive);
  const [isGatewayOpen, setIsGatewayOpen] = useState<boolean>(false);

  useEffect(() => {
    const isOpen =
      window.innerWidth >= 1170 && // must be on a desktop resolution
      !!user?.validSubscription && // must have a valid subscription on the current site
      upgradableSites.length > 0; // must have at least one site where they don't have an active subscription

    setIsGatewayOpen(isOpen);
  }, [upgradableSites, user?.validSubscription]);

  const hasClickCountBeenReached = usePromoPopup(isPopupAllowedOnPage, isGatewayOpen);

  const showPromoPopups = isPromoActive && userLoaded && hasClickCountBeenReached;
  const showGateway = isGatewayOpen && !cookies[showGatewayCookie];

  // doing this since it seems like they want the promo gateway to be every 24 hours. if they want this changed
  // we can go back to the normal 10 day cookie as a default by just removing this line and setting it back below
  const cookieTTL = isPromoActive ? ONE_DAY : TEN_DAYS;

  if (!isPopupAllowedOnPage) {
    return null;
  }

  return (
    <>
      {showGateway && !user?.showMembersPortal && (
        <GatewayModal
          onClose={() => {
            setIsGatewayOpen(false);
            const expires = new Date(new Date().getTime() + cookieTTL);
            setCookie(showGatewayCookie, false, { path: '/', expires });
          }}
        />
      )}
      {showPromoPopups && isPromoPopupActive && !dismissedPopup && (
        <PromoPopup
          onClose={() => {
            setDismissedPopup(true);
          }}
        />
      )}
      {showPromoPopups && isPromoFooterActive && dismissedPopup && <PromoFooterBanner />}
    </>
  );
}
