import React, { Suspense } from 'react';
import { isPrerender } from 'src/client/utils/prerenderDetect';
import Loading from 'src/client/containers/Loading';

const OnPageFooterLazy = isPrerender
  ? require('./OnPageFooter')
  : React.lazy(() =>
      import('src/client/components/OnPageSeo/OnPageFooter/OnPageFooter').then((m) => ({ default: m.OnPageFooter })),
    );

export const OnPageFooter = () => (
  <Suspense fallback={<Loading />}>
    <OnPageFooterLazy />
  </Suspense>
);
