import React from 'react';
import styled from 'styled-components/macro';
import Modal from 'src/client/components/modals/Modal/Modal';

type Props = {
  cdnUrl: string;
  instagram: string;
  onHide: () => void;
  show: boolean;
  siteName: string;
  className?: string;
};

const BaseModal = (props: Props) => {
  const { cdnUrl, instagram, siteName, show, onHide, className } = props;

  return (
    <Modal show={show} onHide={onHide} altCloseButton modalBodyClassName={`${className} model-modal`}>
      <img className="model-message-image" src={`${cdnUrl}/img/model-message-modal.jpg`} alt="" />
      <div className="model-message-section">
        <h4>Coming soon, watch for updates!</h4>
        <p>Watch our models sharing their thoughts and more.</p>
        <a
          className="btn btn-primary join-btn"
          data-value="regular"
          href={`https://instagram.com/${instagram}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {`The official ${siteName} Instagram`}
        </a>
      </div>
    </Modal>
  );
};

export const MessageModal = styled(BaseModal)`
  &.model-modal {
    padding: 0;
    margin: 0;
  }

  & .model-message-image {
    width: 100%;
  }

  & .model-message-section {
    letter-spacing: 0;
    margin: 24px 0;
    text-align: center;

    h4 {
      letter-spacing: -1px;
      font-weight: 700;
    }

    p {
      padding-bottom: 12px;
    }

    .join-btn {
      background: ${(props) => props.theme.headerBarPrimary3};
      color: ${(props) => props.theme.primaryBtn1Text} !important;
      font-weight: 700;
      padding: 8px 28px;
      text-transform: uppercase;
    }

    @media (min-width: 350px) {
      h4 {
        font-size: 22px;
      }

      .join-btn {
        font-size: 16px;
      }
    }
  }
`;
