import React from 'react';
import styled from 'styled-components/macro';
import { createGlobalStyle } from 'styled-components';
import AdvancedModal from '@tovia/man-ui/lib/components/Modal/AdvancedModal';

import { useSelector } from '../../redux/modules/helpers/useSelector';

type Props = {
  show: boolean;
  onClose: () => void;
};

// Mr Skin promo for hustler.
export const CrossSitePopup = (props: Props) => {
  const { crossSitePopupBanner } = useSelector((state) => state.app.config);

  if (!crossSitePopupBanner) return null;

  return (
    <>
      <GobalStyle />
      <StyledModal dialogClassName="cross-site-modal-dialog" onClose={props.onClose} show={props.show}>
        <BackgroundImage
          target="_blank"
          backgroundImage={crossSitePopupBanner.bannerUrl}
          href={crossSitePopupBanner.siteUrl}
        />
      </StyledModal>
    </>
  );
};

// This isn't great, but there isn't another way to target the modal-dialog
// component with styled components.
const GobalStyle = createGlobalStyle`
  .cross-site-modal-dialog {
    max-width: 100%;
    border: 0;
  }
`;

const BackgroundImage = styled.a<{
  backgroundImage: string;
}>`
  border-radius: 6px;
  display: block;
  width: 1133px;
  height: 412px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundImage});
`;

const StyledModal = styled(AdvancedModal)`
  padding: 0;
`;
