import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router';
import 'typeface-open-sans';
import TagManager from 'react-gtm-module';
import AgeCompliance from '../AgeComplianceContainer/AgeComplianceContainer';
import { HeaderBar } from '../HeaderBar/HeaderBar';
import ScrollToTop from './ScrollToTop';
import Splash from '../Splash/Splash/Splash';
import WhiteLabelSplash from '../Splash/Splash/WhiteLabelSplash';
import StraplezSplash from '../Splash/Splash/StraplezSplash';
import NetworkSplash from '../Splash/Splash/NetworkSplash';
import Internal from '../Internal/Internal';
import AffiliateHeader from '../AffiliateHeader/AffiliateHeader';
import { ESUpdates } from '../Updates/ESUpdates/ESUpdates';
import ESHeaderBar from '../Updates/ESUpdates/ESHeaderBar';
import NewTagDialog from '../TagDialog/NewTagDialog';
import { addDashesToUUID } from 'src/shared/converters';
import { load as loadUser } from '../../redux/modules/auth';
import redirectHomepages from 'src/shared/constants/redirectHomepages';
import { useSelector, useSettingsSelector } from '../../redux';
import GlobalStyle from './GlobalStyle';
import '../../theme/all.scss';
import { isPrerender } from '../../utils/prerenderDetect';
import { PopupsManager } from '../AdsZones/PopupsManager';
import { HeaderProvider } from '../HeaderBar/HeaderProvider';
import { AgeVerifySplash } from 'src/client/pages';
import { useExternalScript } from './useExternalScript';
import { LangChangeObserverComponent } from 'src/client/components/observers/LangChangeObserverComponent';
import { HTMLHead } from './HTMLHead';
import { ChatWidget } from './ChatWidget';
import { useAnalytics } from 'src/client/components/Analytics/AnalyticsPageView';
import { MembersPortalFloater } from 'src/client/components/MembersPortal/MembersPortalFloater';
import { getDeviceType } from 'src/client/utils/getDeviceType';
import { CategoryIds, MeasurementTypes } from 'src/shared/constants/userMeasurement';
import { AdultContentDisclaimerBanner } from '../../components/banners/AdultContentDisclaimerBanner';
import { getUserTypeAsString } from 'src/client/helpers/getUserType';

/* JW Player loader */
const activateGoogleOptimize = () => {
  window.dataLayer.push({ event: 'optimize.activate' });
};

if (!isPrerender) {
  // eslint-disable-next-line global-require
  require('../../theme/non-prerender.scss');
}

const Div = styled.div`
  background-color: ${(props) => props.theme.primary2};
  color: ${(props) => props.theme.primary4};

  textarea,
  input:not(.btn) {
    background: ${(props) => props.theme.inputBGColor};
    border: 1px solid ${(props) => props.theme.primary3};
    color: ${(props) => props.theme.inputTextColor};
  }

  .form-control {
    color: ${(props) => props.theme.inputTextColor};
  }

  .content {
    margin: 10px auto;
    padding-bottom: 15px;
    background-color: ${(props) => props.theme.primary1};

    @media (max-width: 767px) and (min-width: 640px) {
      z-index: 1;
    }
  }

  img.c-monster {
    position: fixed;
    bottom: 0;
    right: 0;
    display: none;
  }

  .dropdown-menu {
    background: ${(props) => props.theme.primary1};
    border-color: ${(props) => props.theme.primary3};
  }

  .dropdown-item {
    font-size: 0.9rem;
    display: block;
    text-decoration: none;
    padding: 3px 11px;
    //color: ${(props) => props.theme.primary4};

    :hover {
      background-color: ${(props) => props.theme.primary3};
      text-decoration: none;
    }
  }

  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > span:hover,
  .dropdown-item.active {
    color: ${(props) => props.theme.primary5};
    background-color: ${(props) => props.theme.primary3};
    text-decoration: none;
  }

  .dropdown-text,
  .dropdown-menu > li > a,
  .drop-menu > li > span {
    color: ${(props) => props.theme.primary5};
  }
`;

//SEO_CLEANUP
// removing duplicate urls with a trailing slash
const RemoveTrailingSlash = ({ children }) => {
  const location = useLocation();
  const fullPath = location.pathname + location.search + location.hash;
  if (fullPath !== '/' && fullPath.endsWith('/')) {
    const pathWithoutTrailingSlash = fullPath.slice(0, -1);
    return <Redirect to={pathWithoutTrailingSlash} />;
  }

  return children;
};

export default function App() {
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>();
  const [ssoResponded, setSsoResponded] = useState<boolean>(false);
  const location = useLocation();
  const { user, loaded: userLoaded, userType } = useSelector((state) => state.auth);
  const { isEU, isAC, country } = useSelector((state) => state.auth.location);
  const site = useSelector((state) => state.site);
  const { showLegaLRestrictionSplash } = useSelector((state) => state.app);
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();

  const { ssoUrl, config, googleTagManager, googleAnalytics, googleOptimize, oneTrustDomainID, version } = useSelector(
    (state) => state.app,
  );

  // This hook is used to measure our free/guest members, and their conversion rates
  useAnalytics({
    deviceType: getDeviceType(),
    type: MeasurementTypes.View,
    categoryId: CategoryIds.PageViewsAndNewSubscriptions,
  });

  useExternalScript(
    config.externalScripts?.trafficjunky?.url,
    config.externalScripts?.trafficjunky?.code,
    config.externalScripts?.trafficjunky?.appendCodeAfterUrlLoads,
    config.externalScripts?.trafficjunky?.mamWhitelist,
  );

  const homepage = useSettingsSelector('homepage');
  const dispatch = useDispatch();

  // strip out `siteUserLogin` query param so that it doesn't cause a user to be redirected to main site by accident
  if (query.has('siteUserLogin')) {
    query.delete('siteUserLogin');

    const querystring = query.toString();
    history.replace(`${location.pathname}${querystring ? `?${querystring}` : ''}`);
  }

  useEffect(() => {
    if (iframeRef && !isPrerender && !user && userLoaded) {
      const ssoReceiveMessage = (event) => {
        if (event.source !== iframeRef?.contentWindow || event.origin !== ssoUrl) {
          return;
        }

        setSsoResponded(true);

        if (event.data === true) {
          window.location.href = `/auth/man?r=${window.location.pathname}`;
        }
      };

      let ssoTimeout;
      const pingSSO = () => {
        if (ssoResponded) {
          return true;
        }
        iframeRef?.contentWindow?.postMessage('ping', '*');
        ssoTimeout = setTimeout(pingSSO, 1000);
        return false;
      };

      pingSSO();

      window.addEventListener('message', ssoReceiveMessage, false);

      return () => {
        clearTimeout(ssoTimeout);
        window.removeEventListener('message', ssoReceiveMessage, false);
      };
    }

    return () => {};
  }, [ssoResponded, setSsoResponded, ssoUrl, iframeRef, user, userLoaded]);

  useEffect(() => {
    if (user) {
      const userId = addDashesToUUID(user.UUID);
      window.dataLayer.push({ event: 'pageview' });
      if (user?.firstVisit) {
        window.mixpanel.alias(userId);
      } else {
        window.mixpanel.identify(userId);
      }
      window.mixpanel.people.set({
        $email: user.email,
        validSubscription: user.validSubscription,
        username: user.username,
      });
    }
    window.dataLayer.push({
      event: 'pageview',
      user_type: getUserTypeAsString(userType),
    });
  }, [user, userType]);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if (googleOptimize) {
      activateGoogleOptimize();
    }
  }, [googleOptimize, location.pathname]);

  useEffect(() => {
    if (userLoaded) {
      try {
        const htmlTag = document.documentElement;
        htmlTag.classList.remove('loading');
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [userLoaded]);

  useEffect(() => {
    if (!isPrerender && location.hash.includes('&login')) {
      const url = document.location.href;
      const param = 'login';
      const re = new RegExp(`#.*[?&]${param}=([^&]+)(&|$)`);
      const match = url.match(re);
      const method = match ? match[1] : null;
      if (method) {
        window.dataLayer.push({
          event: 'login',
          method,
        });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (!isPrerender && version && userLoaded && googleTagManager) {
      const dataLayer: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      } = {
        goptID: googleOptimize?.containerID,
        gaTID: googleAnalytics?.UATrackingID,
        ga4TID: googleAnalytics?.['4TrackingID'],
        oneTrustID: oneTrustDomainID,
        appVersion: version,
      };
      if (user) {
        dataLayer.userId = addDashesToUUID(user.UUID);
      }
      TagManager.initialize({
        gtmId: googleTagManager.id,
        dataLayer,
      });
    }
  }, [version, user, userLoaded, googleOptimize, googleTagManager, googleAnalytics, oneTrustDomainID]);

  // https://account-local.metartnetwork.com/api/tracking?UUID=u4&type=t4
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const age = queryParams.has('age');

  if (showLegaLRestrictionSplash) {
    return (
      <>
        <GlobalStyle />
        <HTMLHead />
        <Switch>
          <Route path="*" component={AgeVerifySplash} />
        </Switch>
      </>
    );
  }

  return (
    <>
      <Div>
        <LangChangeObserverComponent />
        <GlobalStyle />
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
        <ScrollToTop>
          <div className="app" data-country={country} data-is-eu={isEU}>
            {(isAC || age) && !config.isWhiteLabel && <AgeCompliance />}
            {!isPrerender && !user && userLoaded && (
              <iframe
                title="sso"
                id="auth-iframe"
                src={`${ssoUrl}/auth/iframe/${site.UUID}`}
                style={{ display: 'none' }}
                ref={setIframeRef}
                // onLoad={function () {
                // this.contentWindow.postMessage('ping', '*');
                // }}
              />
            )}

            <HTMLHead />

            <RemoveTrailingSlash>
              <Switch>
                {config.esUpdates.active && <Route exact path="/esupdates" component={ESHeaderBar} />}
                <Route
                  render={() => (
                    <HeaderProvider>
                      <HeaderBar />
                      <AffiliateHeader />
                    </HeaderProvider>
                  )}
                />
              </Switch>

              <Switch>
                {config.esUpdates.active && <Route exact path="/esupdates" component={ESUpdates} />}
                {config.isWhiteLabel && config.splashPageType === 'whitelabel' && (
                  <Route exact path="/" render={(props) => <WhiteLabelSplash {...props} />} />
                )}
                {config.isWhiteLabel && config.splashPageType === 'none' && (
                  <>
                    <Redirect from="/" exact to="/members" />
                    <Route exact path="/members" render={(props) => <WhiteLabelSplash {...props} />} />
                  </>
                )}
                {config.splashPageType === 'straplez' && (
                  <Route
                    exact
                    path="/"
                    render={() =>
                      config.splash?.active ? <StraplezSplash /> : <Redirect to={redirectHomepages[homepage]} />
                    }
                  />
                )}
                {config.splashPageType === 'network' && (
                  <Route
                    exact
                    path="/"
                    render={() =>
                      config.splash?.active ? <NetworkSplash /> : <Redirect to={redirectHomepages[homepage]} />
                    }
                  />
                )}
                {(!config.isWhiteLabel || config.splashPageType === 'splash') && (
                  <Route
                    exact
                    path="/"
                    render={() => (config.splash?.active ? <Splash /> : <Redirect to={redirectHomepages[homepage]} />)}
                  />
                )}
                <Route component={Internal} />
              </Switch>
            </RemoveTrailingSlash>
            <NewTagDialog />
            <PopupsManager />
          </div>
        </ScrollToTop>
      </Div>
      <ChatWidget
        jira-id="9dab1c19-00bf-3be7-b9b6-14a91ae44549"
        service-desk-id="3"
        validSubscription={user?.validSubscription || false}
      />
      <MembersPortalFloater />
      <AdultContentDisclaimerBanner />
      <div id="portals" />
    </>
  );
}
