import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { useIntl } from 'react-intl';

import categoryDirector from './images/category_director.jpg';
import categoryVacation from './images/category_vacation.jpg';
import categoryCasting from './images/category_casting.jpg';
import categoryProduction from './images/category_production.jpg';
import { JoinButton } from 'src/client/components/buttons/JoinButton';

export const SpecialFeaturesClass = ({ className, hideSignUp }) => {
  const intl = useIntl();
  return (
    <section className={`${className} special-features`}>
      <div className="container">
        <div className="special-features-title">
          {intl.formatMessage({
            id: 'splash.special_features.als.headline',
            defaultMessage: 'Special Features: Inside ALS World',
          })}
        </div>
        <div className="special-features-content">
          <div className="special-features-picks">
            <NavLink to="/category/Director_Picks" className="special-features-pick">
              <div className="colorized director">
                {intl.formatMessage({
                  id: 'splash.special_features.als.director_picks',
                  defaultMessage: 'ALS/Director Picks',
                })}
              </div>
              <img src={categoryDirector} alt="" />
            </NavLink>
            <NavLink to="/category/Vacations" className="special-features-pick">
              <div className="colorized vacations">
                {intl.formatMessage({ id: 'splash.special_features.als.vacations', defaultMessage: 'ALS/Vacations' })}
              </div>
              <img src={categoryVacation} alt="" />
            </NavLink>
            <NavLink to="/category/Casting_Calls" className="special-features-pick">
              <div className="colorized casting">
                {intl.formatMessage({
                  id: 'splash.special_features.als.casting_calls',
                  defaultMessage: 'ALS/Casting Calls',
                })}
              </div>
              <img src={categoryCasting} alt="" />
            </NavLink>
            <NavLink to="/category/Productions" className="special-features-pick">
              <div className="colorized productions">
                {intl.formatMessage({
                  id: 'splash.special_features.als.productions',
                  defaultMessage: 'ALS/Productions',
                })}
              </div>
              <img src={categoryProduction} alt="" />
            </NavLink>
          </div>
          {!hideSignUp && (
            <div className="special-features-access">
              <section className="access-section">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: 'splash.special_features.starlets',
                      defaultMessage: "1000's of starlets",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'splash.special_features.favorites',
                      defaultMessage: 'Create favorites',
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: 'splash.special_features.downloads',
                      defaultMessage: 'Unlimited downloads',
                    })}
                  </li>
                </ul>
                <ul>
                  <li>
                    {intl.formatMessage({ id: 'splash.special_features.images', defaultMessage: '500,000 images' })}
                  </li>
                  <li>1000&apos;s of movies</li>
                  <li>
                    {intl.formatMessage({ id: 'splash.special_features.live_cams', defaultMessage: 'Sexy live cams' })}
                  </li>
                </ul>
              </section>
              <section className="access-section">
                <JoinButton campaign="special-features" className="btn btn-primary special-features-btn">
                  {intl.formatMessage({ id: 'splash.special_features.signup', defaultMessage: 'Sign Up Today' })}
                </JoinButton>
              </section>
              <section className="access-section">
                <NavLink to="/updates" className="tour-btn">
                  {intl.formatMessage({
                    id: 'splash.special_features.free_tour',
                    defaultMessage: '>> Enjoy a free tour',
                  })}
                </NavLink>
              </section>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

SpecialFeaturesClass.propTypes = {
  className: PropTypes.string,
  hideSignUp: PropTypes.bool,
};

SpecialFeaturesClass.defaultProps = {
  className: '',
  hideSignUp: false,
};

export default withTheme(styled(SpecialFeaturesClass)`
  &.special-features {
    background-color: ${(props) => (!props.hideSignUp ? props.theme.splashOptional1 : 'inherit')};
    padding: 36px 0;
  }

  & .special-features-title {
    color: ${(props) => props.theme.splashPrimary1};
    background-color: ${(props) => props.theme.primary9};
    display: inline-block;
    font-size: 1.72rem;
    padding: 12px 24px;
    text-transform: uppercase;
  }

  & .special-features-content {
    background-color: ${(props) => props.theme.splashPrimary1};

    @media (min-width: 768px) {
      padding: 24px;
    }
  }

  & .special-features-picks {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    @media (min-width: 568px) {
      flex-direction: row;
      flex-flow: wrap;
    }

    @media (min-width: 768px) {
      flex-flow: unset;
    }

    .special-features-pick {
      margin: 0 8px 8px;
      padding-top: 0;
      width: calc(100% - 16px);

      @media (min-width: 568px) and (max-width: 767px) {
        width: calc(50% - 16px);
      }

      @media (min-width: 768px) {
        margin: unset;
        flex-grow: 1;
        flex-basis: 0;
        width: 100%;
      }

      .colorized {
        color: ${(props) => props.theme.splashPrimary1};
        display: block;
        font-size: 1.15rem;
        padding: 8px;

        @media (min-width: 1024px) {
          font-size: 1.3rem;
        }

        &.director {
          background-color: ${(props) => props.theme.primary9};
        }
        &.vacations {
          background-color: ${(props) => props.theme.primary10};
        }
        &.casting {
          background-color: ${(props) => props.theme.primary12};
        }
        &.productions {
          background-color: ${(props) => props.theme.primary11};
        }
      }

      &:not(:last-child) {
        @media (min-width: 768px) {
          margin-right: 8px;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  & .special-features-access {
    display: flex;
    flex-direction: column;
    margin-top: 4px;

    @media (min-width: 768px) {
      flex-flow: unset;
    }

    .access-section {
      background-color: ${(props) => props.theme.splashOptional1};
      width: 100%;

      @media (min-width: 568px) {
        //width: 50%;
      }

      @media (min-width: 768px) {
        flex-grow: 1;
        flex-basis: 0;
        padding: 36px 0;
      }

      ul {
        margin: 0 12px;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: 414px) and (max-width: 767px) {
          float: left;
          text-align: unset;
          width: calc(50% - 24px);
        }

        @media (min-width: 1200px) {
          float: left;
          text-align: unset;
          width: unset;
        }

        li {
          list-style-type: none;
          margin: 4px 0;
        }
      }

      &:not(:last-child) {
        @media (min-width: 768px) {
          margin-right: 8px;
        }
      }
    }

    .special-features-btn {
      color: ${(props) => props.theme.splashPrimary1};
      display: block;
      font-size: 1.4rem;
      margin: 0 24px;
      padding: 12px 0;
      position: relative;

      @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .tour-btn {
      display: block;
      color: ${(props) => props.theme.splashPrimary2};
      font-size: 1.15rem;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
      }

      @media (min-width: 1200px) {
        font-size: 2rem;
      }
    }
  }
`);
