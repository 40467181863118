/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
// import './browserUpdate';

import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import registerServiceWorker, { unregisterServiceWorker } from './client/registerServiceWorker';

import { App } from './client/containers/App';
import { LanguageProvider, ThemeProvider, ReduxStoreProvider } from './client/components/Providers';
import { loadAppData } from './client/helpers';
import { setupInitialReduxState } from './client/helpers';
import { DebugProvider } from './client/components/Providers/DebugProvider';
import { CamsContextProvider } from './client/containers/Cams/CamsContextProvider';
import { WidePageContextProvider } from './client/containers/App/WidePageContextProvider';

const queryClient = new QueryClient();

const loadApp = (initialState) => {
  const reactRoot = document.getElementById('content');
  const ReduxProvider = process.env.NODE_ENV === 'production' ? ReduxStoreProvider : DebugProvider;

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <ReduxProvider initialState={initialState}>
          <BrowserRouter>
            <ThemeProvider colorThemes={initialState.theme.colors}>
              <CamsContextProvider>
                <LanguageProvider>
                  <WidePageContextProvider>
                    <App />
                  </WidePageContextProvider>
                </LanguageProvider>
              </CamsContextProvider>
            </ThemeProvider>
          </BrowserRouter>
        </ReduxProvider>
      </CookiesProvider>
    </QueryClientProvider>,
    reactRoot,
  );

  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger
    unregisterServiceWorker();
  } else {
    registerServiceWorker('/sw.js');
  }
};

loadAppData()
  .then(setupInitialReduxState)
  .then(loadApp)
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Cant get start application', error);
  });
