import { Site } from 'src/client/redux/modules/site';

export const getCheckoutPageUrl = (
  url: string,
  subscription: BaseSubscription,
  offerFreeSiteProduct: boolean,
  domain: string,
  joinSubdomain: string,
) => {
  const { siteUUID, billerID } = subscription;

  const joinUrl = new URL(`https://${joinSubdomain}.${domain.toLowerCase()}/upgrades/checkout`);
  joinUrl.search = new URLSearchParams({
    promo: btoa(
      JSON.stringify({
        billerID,
        siteUUID,
        offerFreeSiteProduct,
      }),
    )
      .toString()
      .replace(/=/g, ''),
  }).toString();

  const redirectUrl = new URL(url);
  redirectUrl.search = new URLSearchParams({
    url: joinUrl.toString(),
  }).toString();

  return redirectUrl.toString();
};

const campaigns = {
  // first two don't apply here since they are mostly for emails
  over_23: 7463,
  under_23: 7466,
  // everything below here should be used in the MAM URLs found in the popups
  one_week: 7469, // when the user has 7 days or less left on their subscription
  expire_day: 7472, // when the user "expires" and gets 7 free promo days added
  after_expire: 7475, // when the 7 free promo days are over, fully expired subscription
};

const siteCode = {
  MA: '32.MA.1.2.5.0',
  SA: '32.SA.13.27.5.0',
  MX: '32.MX.23.255.5.0',
  VT: '32.VT.15.32.5.0',
  SL: '32.SL.26.1163.5.0',
  TLE: '32.TLE.14.29.5.0',
  ALS: '32.ALS.8.15.5.0',
  DM: '32.DM.18.117.5.0',
  EB: '32.EB.2.4.5.0',
  EA: '32.EA.6.9.5.0',
  ED: '32.ED.9.20.5.0',
  GN: '32.GN.19.118.5.0',
  LH: '32.LH.25.386.5.0',
  RA: '32.RA.12.25.5.0',
  S18: '32.S18.16.35.5.0',
};

export const generateCampaignUrl = (site: Site, timePeriod: keyof typeof campaigns, accessSubdomain: string) => {
  const abbreviation = site.abbreviation.toUpperCase();
  const domain = site.domain.toLowerCase();

  const code = siteCode[abbreviation];
  const campaign = campaigns[timePeriod];

  return `https://${accessSubdomain}.${domain.toLowerCase()}/track/${code}.${campaign}`;
};
