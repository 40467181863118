import React from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faImages, faVideo } from '@fortawesome/pro-regular-svg-icons';
import Card from '@tovia/man-ui/lib/components/Card/Card';
import Image from '@tovia/man-ui/lib/components/Image/Image';
import { Link } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import { Badge, Badges, RatingOverlay } from '@tovia/man-ui';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { formatDateDefaultOptions, secondsToRuntime } from 'src/client/utils/converters';
import ProgressBar from 'src/client/components/ProgressBar/ProgressBar';
import { RibbonCorner } from 'src/client/components/Ribbon/RibbonCorner';
import { CardModelLinks } from 'src/client/containers/Cards/CardModelLinks';
import { GalleryModel } from '@tovia/man-protos/dist/types/content.types';
import { BlurredImage } from 'src/client/components/ui/BlurredImage';

type Props = {
  categories?: string[];
  favoriteCount?: number;
  imageCount?: number;
  imgAlt: string;
  imgSrc: string;
  isBlurred: boolean;
  isIntimateSelection?: boolean;
  isMovie?: boolean;
  isNetworkWideGallery?: boolean;
  isPromoGallery?: boolean;
  isStaffSelection?: boolean;
  models: Pick<GalleryModel, 'name' | 'path'>[];
  movieProgress?: number;
  // @TODO fix these any's
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  photographer: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ratingStars: any;
  runtime?: number;
  showCategories?: boolean;
  showModelName?: boolean;
  showPhotographer?: boolean;
  timestamp?: string;
  title: string;
  url: string;
  useExternalLinkForModelOrArtist?: boolean;
  views?: string | number;
};

export const GalleryDetailedCard = (props: Props) => {
  const {
    categories = [],
    favoriteCount = 0,
    imageCount = 0,
    imgAlt,
    imgSrc,
    isBlurred,
    isMovie,
    isNetworkWideGallery,
    isPromoGallery,
    models,
    movieProgress = 0,
    photographer,
    ratingStars,
    runtime = 0,
    showCategories,
    showPhotographer = true,
    timestamp = '',
    title,
    url,
    useExternalLinkForModelOrArtist,
    views = 0,
  } = props;

  const intl = useIntl();
  const ribbonContent = isMovie ? 'BONUS FILM' : 'BONUS SET';

  const usersRating = ratingStars.rating !== 0 ? ratingStars.rating : 'Not rated yet';

  const attrs = [
    {
      label: intl.formatMessage({ id: 'detailedCard.released', defaultMessage: 'Released' }),
      value: <FormattedDate value={new Date(timestamp)} {...formatDateDefaultOptions} month="short" />,
    },
    {
      label: intl.formatMessage({ id: 'detailedCard.featuring', defaultMessage: 'Featuring' }),
      value: <CardModelLinks models={models} useExternalLinkForModelOrArtist={useExternalLinkForModelOrArtist} />,
    },
    ...(showPhotographer && !props.isIntimateSelection
      ? [
          {
            label: runtime
              ? intl.formatMessage({ id: 'detailedCard.director', defaultMessage: 'Director' })
              : intl.formatMessage({ id: 'detailedCard.photographer', defaultMessage: 'Photographer' }),
            value: useExternalLinkForModelOrArtist ? (
              <a href={photographer.url} key={photographer.url}>
                {photographer.text}
              </a>
            ) : (
              <Link to={photographer.url} key={photographer.url}>
                {photographer.text}
              </Link>
            ),
          },
        ]
      : []),
    ...(showCategories && categories.length > 0
      ? [
          {
            label: intl.formatMessage({ id: 'detailedCard.categories', defaultMessage: 'Categories' }),
            value: categories
              .map((cat) => (
                <Link to={`/search/${cat.replace(/\s+/g, '+')}`} key={`${title}_${cat}`}>
                  {cat}
                </Link>
              ))
              .reduce((result, value) => (
                <>
                  {result}, {value}
                </>
              )),
          },
        ]
      : []),
    {
      label: ratingStars.userRating
        ? intl.formatMessage({ id: 'detailedCard.yourRating', defaultMessage: 'Your Rating' })
        : intl.formatMessage({ id: 'detailedCard.rating', defaultMessage: 'Rating' }),
      value: (
        <RatingOverlay ratingStars={ratingStars}>
          <span className="rating-overlay">{ratingStars.userRating ? ratingStars.userRating : usersRating}</span>
        </RatingOverlay>
      ),
    },
  ];

  return (
    <Wrapper className="detailed-card-wrapper">
      <Card className={cx({ highlighted: isNetworkWideGallery })}>
        <div className="photo" style={{ position: 'relative' }}>
          <RibbonCorner text={isPromoGallery ? ribbonContent : ''} ribbonType="detail" />
          <Link to={url}>
            <Image src={imgSrc} alt={imgAlt} width="100%" height="100%" lazy animate />
            {isBlurred && <BlurredImage src={imgSrc} alt={imgAlt} width="100%" height="100%" lazy />}
          </Link>
          <ProgressBar progress={movieProgress} />
        </div>
        <div className="photo-stats">
          <h3 title={title}>
            <Link to={url}>{title}</Link>
          </h3>
          <div className="attrs">
            {attrs.map((attr) => (
              <div className="attr" key={attr.label}>
                <span className="attr-label">{attr.label}: </span>
                <span className="attr-value">{attr.value}</span>
              </div>
            ))}
          </div>
          <Badges>
            {imageCount ? <Badge key="photos" text={imageCount} icon={<FontAwesomeIcon icon={faImages} />} /> : null}
            {runtime > 0 ? (
              <Badge key="runtime" text={secondsToRuntime(runtime)} icon={<FontAwesomeIcon icon={faVideo} />} />
            ) : null}
            <Badge key="views" text={views} icon={<FontAwesomeIcon icon={faEye} />} />
            <Badge key="favorites" text={favoriteCount} icon={<FontAwesomeIcon icon={faThumbsUp} />} />
          </Badges>
        </div>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &:not(.menu-opened) {
    &:hover {
      .card-menu-overlay {
        opacity: 1;
      }
    }
  }

  .card-menu-overlay {
    transition: 0.3s opacity;
    opacity: 0;
    color: #fff;
  }

  .card-info-1 {
    padding-bottom: 0;
  }
  .card-info-2 {
    padding-top: 0;
  }

  .card-media {
    overflow: hidden;
  }
`;
