import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import backgroundImageUrl from './images/bg_desktop.jpg';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { isPrerender } from '../../utils/prerenderDetect';
import { usePages } from 'src/client/helpers/usePages';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

export const cookieName = 'showAgeCompliance';

const Div = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${backgroundImageUrl});
  background-color: #181818;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  & .welcome-container {
    max-height: 435px;
    position: fixed;
    left: 50%;
    text-align: center;
    z-index: 2000;
    max-width: 400px;
    min-width: 300px;
    transform: translateX(-50%);
    top: 140px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .welcome {
    text-align: center;
    margin: 25px 0 20px;
    font-size: 0.85rem;
    color: #e0e0e0;
  }

  & .first-logo,
  & .second-logo {
    margin: 10px auto 35px;
    border: 0;
    display: block;
    width: 100%;
    padding: 0 12px;
  }

  & .second-logo {
    margin: 0 auto 45px;
  }

  & .age-warning {
    display: block;
    font-size: 1.3rem;
    font-weight: 700;
    color: #e0e0e0;
  }

  & .first-line,
  & .second-line {
    display: block;
    margin: 0 0 12px;
    font-size: 1rem;
    color: #e0e0e0;
  }

  & .second-line {
    margin: 0 0 28px;
  }

  & .enter-button {
    border: 0;
    outline: none;
    font-size: 1.6rem;
    font-weight: 700;
    display: block;
    text-decoration: none;
    width: 90%;
    margin: 0 auto 30px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    color: #fff;
    background-color: #5bfa80;
    background: linear-gradient(to bottom, #5bfa80 0%, #01c853 100%);

    :hover {
      cursor: pointer;
    }
  }

  & .legal {
    position: fixed;
    bottom: 30px;
    left: 50%;
    text-align: center;
    z-index: 2000;
    max-width: 700px;
    min-width: 300px;
    transform: translateX(-50%);
  }

  & .last-line,
  & .link {
    font-size: 1rem;
    color: #7f7f7f;

    :hover,
    :active {
      display: block;
      font-size: 1rem;
      color: #7f7f7f;
    }
  }

  & .link {
    :hover,
    :active {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 400px) {
    & .enter-button {
      height: 40px;
      line-height: 40px;
      font-size: 1.3rem;
    }
  }

  @media screen and (max-height: 630px) {
    & .welcome-container {
      top: 20px;
    }
  }

  @media screen and (max-width: 620px) {
    & .legal {
      bottom: 10px;
      font-size: 0.85rem;
    }

    & .link {
      font-size: 0.85rem;

      :hover,
      :active {
        margin: 0;
        font-size: 0.85rem;
      }
    }
  }
`;

export default function AgeComplianceContainer() {
  const [cookies, setCookie] = useCookies([cookieName]);
  const intl = useIntl();
  const location = useLocation();
  const path2257 = usePages('/2257');

  const onAgreeClick = useCallback(() => {
    const expires = new Date('Fri, 31 Dec 9999 23:59:59 GMT');
    setCookie(cookieName, false, { expires, path: '/' });
  }, [setCookie]);

  const site = useSelector((state) => state.site);
  const user = useSelector((state) => state.auth.user);
  const authLoaded = useSelector((state) => state.auth.loaded);
  const cdnUrl = useSelector((state) => state.app.cdnUrl);

  useEffect(() => {
    // skip for crawling bots, users, over18 param or cookie
    if ((user || location.hash.match(/over18/) || isPrerender) && !cookies[cookieName]) {
      onAgreeClick();
    }
  }, [onAgreeClick, cookies, site, user, location.hash, location.pathname]);

  if (!authLoaded || location.pathname.includes('/2257') || cookies[cookieName]) {
    return null;
  }

  const currentYear = new Date().getFullYear();

  return (
    <Div className="age-compliance">
      <div className="welcome-container">
        <p className="welcome">{intl.formatMessage({ id: 'ageCompliance.welcome', defaultMessage: 'Welcome' })}</p>
        <img src={logoUrl(cdnUrl, site)} alt={site.name} className="first-logo" />
        <p className="age-warning">
          {intl.formatMessage({ id: 'ageCompliance.warning', defaultMessage: 'WARNING - 18+ ADULTS ONLY' })}
        </p>
        <p className="first-line">
          {intl.formatMessage({
            id: 'ageCompliance.eighteenYears',
            defaultMessage: 'You must be at least 18 years old to enter',
          })}
        </p>
        <p className="second-line">
          {intl.formatMessage({ id: 'ageCompliance.please', defaultMessage: 'Please' })}{' '}
          <b>
            {intl.formatMessage({ id: 'ageCompliance.verifyAge', defaultMessage: 'click below to verify your age' })}
          </b>
        </p>
        <button type="button" id="agree" className="enter-button" onClick={onAgreeClick}>
          {intl.formatMessage({ id: 'ageCompliance.buttons.enter', defaultMessage: 'ENTER' })}
        </button>
      </div>
      <div className="legal">
        <NavLink to={path2257} className="link">
          18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
        </NavLink>
        <p className="last-line">
          {intl.formatMessage({
            id: 'ageCompliance.all_materials',
            defaultMessage: 'All materials',
          })}
          © {currentYear} {site.domain}.
          {intl.formatMessage({
            id: 'ageCompliance.disclaimer',
            defaultMessage: 'All models photographed were at least 18 years old.',
          })}
        </p>
      </div>
    </Div>
  );
}
