import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
      html.app {
        min-height: 100vh;
        font-size: 14px;
      }

      html.app body {
        font-family: 'Open Sans', sans-serif;
        letter-spacing: .5px;
        min-height: 100vh;
        overflow-x: hidden;
      }

      #jsd-widget {
       opacity: 0;
       visibility: hidden;
      }

      .show-jsd #jsd-widget {
        opacity: 1;
        visibility: visible;
      }

      .list-inline > div {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
      }

      .clear {
        clear: both;
      }


      .showing-imageViewer {
        overflow: hidden;
        overscroll-behavior-x: none;

        .zEWidget-launcher {
          display: none;
        }
      }

      .btn-primary {
        background-color: ${(props) => props.theme.primaryBtn1Bg};
        border-color: transparent;
        color: ${(props) => props.theme.primaryBtn1Text};

        &[disabled],
        :hover,
         :active,
         :focus,
         :active:focus,
          :active:hover {
          outline: 0;
          background-color: ${(props) => props.theme.primaryBtn1HoverBg};
          border-color: transparent;
          color: ${(props) => props.theme.primaryBtn1Text};
        }

        &[disabled] {
          opacity: 0.8;
        }
      }

      .btn-secondary {
        background-color: ${(props) => props.theme.secondaryBtn1Bg};
        border-color: transparent;
        color: ${(props) => props.theme.secondaryBtn1Text};

        &[disabled],
        :hover,
         :active,
         :focus,
         :active:focus,
          :active:hover {
          outline: 0;
          background-color: ${(props) => props.theme.secondaryBtn1HoverBg};
          border-color: transparent;
          color: ${(props) => props.theme.secondaryBtn1Text};
        }

        &[disabled] {
          opacity: 0.8;
        }
      }

      .modal-dialog.themed {
        .modal-content {
          background-color: ${(props) => props.theme.primary1};
        }
      }

      .modal-content {
        margin: 0 auto;
      }

      .fade.show {
        opacity: 1;

        &.blur {
          backdrop-filter: blur(20px);
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 1;
        }
      }

      .modal-backdrop.show {
        opacity: 0.5;
      }

      .modal.fade .modal-dialog {
        transform: translate(0, 0);
      }

      .modal-dialog-centered {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100% - (.5rem * 2));
      }

      .modal-dialog {
        margin: 0 auto;
      }

      @media screen and (max-width: 767px) {
        .tooltip {
          display: none !important
        }
      }

      [data-path="/"] {
        .search-wrapper {
          display: none;
        }
      }

      // Bootstrap overrides
      .panel {
        background-color: inherit;
        border: 1px solid ${(props) => props.theme.primary3} !important;
      }

      // Man-ui overrides
      .man-ui.tabs {
        background: ${(props) => props.theme.primary6} !important;

        &.full {
          border-bottom: 1px solid ${(props) => props.theme.primary3} !important;

          .tab {
            &.active {
              background: ${(props) => props.theme.primary1} !important;
              border:  1px solid ${(props) => props.theme.primary3} !important;
              border-bottom: none !important;
            }

            :hover {
              background: ${(props) => props.theme.primary1} !important;
            }
          }
        }

        &.flat {
          .tab {
            &.active, :hover {
              background: ${(props) => props.theme.primary3} !important;
            }
          }
        }

        .tab {
          background: transparent;
          color: ${(props) => props.theme.primary4} !important;

          span {
            color: ${(props) => props.theme.primary7} !important;
          }

        }
      }

      .man-ui.card {
        background: ${(props) => props.theme.primary6};
        display: block;

        .card-information-title {
          color: ${(props) => props.theme.primary4};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .card-information-badges {
          color: ${(props) => props.theme.primary7};
          @media (max-width: 992px) {
            position: relative;
            bottom: auto;
            padding: 2px 5px;
            right: auto;
          }
        }

        .card-media{
          :hover {
            .card-media-overlay-icon {
              opacity: 0.7;
            }
          }

          .card-media-overlay-icon {
            opacity: 0;
            transition: opacity 0.3s ease-out 0s;

            svg {
              height: 84px;
              width: 84px;
            }
          }
        }
      }

      .man-ui.navigation-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .man-ui.panels {
        .man-ui.panel {
          border-color: ${(props) => props.theme.primary3};
          box-shadow: none;
        }
      }

      .man-ui.badges {
        .badge {
          display: flex;
          justify-content: center;
        }
        .badge-icon {
          display: inline-flex;
          justify-items: center;
          align-items: center;
            svg {
              height: 16px;
            }
        }
      }

      .pagination, .letter-pagination {
        margin: 10px 0;
        a {
          background: ${(props) => props.theme.primary3} !important;
          color: ${(props) => props.theme.primary7} !important;
          &.active, &:hover {
            background: ${(props) => props.theme.primary7} !important;
            color: ${(props) => props.theme.primary1} !important;
          }
        }
      }

      .man-ui.placeholder {
        .image, .line {
          background-image: linear-gradient(to right,${(props) => props.theme.placeholderPrimary1} 0%,${(props) =>
  props.theme.placeholderPrimary2} 20%,${(props) => props.theme.placeholderPrimary1} 40%,${(props) =>
  props.theme.placeholderPrimary1} 100%);
        }
      }

      a,
      .label {
        color: ${(props) => props.theme.primary4};
        &:hover,
         :focus {
          color: ${(props) => props.theme.primary4};
        }
      }
    `;
