import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Helmet from 'react-helmet';
import { Tab, Tabs } from '@tovia/man-ui';
import { ContentHeader } from 'src/client/components/ui/ContentHeader';
import { Link } from 'react-router-dom';
import LoaderWrapper from '../../../components/Loader/LoaderWrapper';
import { load } from '../../../redux/modules/updates';
import { secondsToRuntime } from '../../../utils/converters';
import { UpdateStreamCard } from '../../../components/cards';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';
import { usePages } from 'src/client/helpers/usePages';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { generateGalleryAlt } from 'src/client/utils/generateGalleryAlt';
import { FormattedDate } from 'react-intl';
import { useGetGalleryCoverUrl } from 'src/client/helpers/urls/useGetGalleryCoverUrl';
import { useShowSfwSite } from 'src/client/helpers/useFeatureFlags';

export const ESUpdates = () => {
  const {
    config: { components, esUpdates },
    contentCdnUrl,
  } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const { error, galleries, loading } = useSelector((state) => state.updates);

  const getGalleryCover = useGetGalleryCoverUrl();
  const showSfwSite = useShowSfwSite();
  const path2257 = usePages('/2257');

  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(
      load({
        tab: 'esupdates',
        direction: 'DESC',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [joinUrl] = useJoinUrl({ campaign: 'es-updates' });

  const loadGalleryStreamItems = (start, end) =>
    galleries.slice(start, end).map((gallery) => {
      const {
        name,
        publishedAt,
        type,
        imageCount,
        views,
        ratingAverage,
        favoriteCount,
        models,
        UUID,
        path,
        runtime,
        categories,
      } = gallery;

      const galleryCover = getGalleryCover(contentCdnUrl, gallery, { clean: true });

      const galleryProps = {
        UUID,
        title: name,
        alt: generateGalleryAlt(name, models),
        publishedAt: <FormattedDate value={new Date(publishedAt)} year="2-digit" month="long" />,
        type,
        views,
        ratingAverage,
        favoriteCount,
        galleryUrl: path,
        galleryCoverUrl: galleryCover.url,
        isBlurred: showSfwSite && !galleryCover.canShowSfw,
        modelName: models.map((model) => model.name).join(', '),
        runtime: secondsToRuntime(runtime),
        imageCount,
        showGalleryName: components?.contentList?.showGalleryName,
        showCategories: components?.contentList?.showCategories,
        categories,
      };
      return <UpdateStreamCard {...galleryProps} key={UUID} />;
    });

  const contentHeader = (number) => {
    const { activeTabs, tabs } = esUpdates;

    return (
      <ContentHeaderWrapper>
        <ContentHeader
          tabs={
            <Tabs flat>
              <Tab as={Link} active value="call-to-action" to={joinUrl} name={activeTabs[number]} />
              <Tab as={Link} value="tagline" to={joinUrl} name={tabs[number]} />
            </Tabs>
          }
        />
      </ContentHeaderWrapper>
    );
  };

  const renderContent = () => {
    const { length } = galleries;
    const nodes: ReactElement[] = [];

    for (let i = 0; i < length; i += 12) {
      nodes.push(
        <div key={`slice_${i}_${i + 12}`}>
          {contentHeader(i / 12)}
          <div className="content-stream-items">{loadGalleryStreamItems(i, i + 12)}</div>
        </div>,
      );
    }

    return nodes;
  };

  return (
    <>
      <ContentStream className="content container">
        <Helmet>
          <title>{`${site.name} - ES Updates`}</title>
          <meta property="og:title" content={`${site.name} - ES Updates`} />
          <meta property="og:url" content={`https://www.${site.domain}/esupdates`} />
        </Helmet>
        {loading || error ? (
          <LoaderWrapper
            defaultHeight
            reloadFunction={loadData}
            loadingStatus={loading}
            reduxErrorStatus={error}
            contentType="models"
          />
        ) : (
          <div>
            {renderContent()}
            {contentHeader(4)}
          </div>
        )}
      </ContentStream>
      <ComplianceSection>
        <a href={path2257}>18 U.S.C. 2257 Declaración de cumplimiento de requisitos de mantenimiento de registros.</a>
        <br />
        <span>
          {`Todos los derechos © 2019 ${site.domain}. Todos los modelos fotografiados tenían al menos 18 años de edad.`}
        </span>
      </ComplianceSection>
    </>
  );
};

const ContentHeaderWrapper = styled.div`
  padding: 0 15px;
`;

const ContentStream = styled.div`
  .content-stream-items {
    margin-bottom: 20px;
  }

  .tab {
    :not(.active) {
      @media (max-width: 1199px) {
        display: none;
      }
    }
  }
`;

const ComplianceSection = styled.div`
  background: #000;
  color: #fff;
  padding: 40px 16px 20px;
  text-align: center;

  a {
    color: inherit;
    display: inline-block;

    :hover {
      color: inherit;
      text-decoration: underline;
    }

    @media (max-width: 767px) {
      margin-bottom: 12px;
    }
  }
`;
