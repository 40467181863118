import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  galleryCoverUrlHover: string;
  galleryVideoUrl: string;
};

const GalleryVideoBackground = styled.video`
  max-width: 100%;
  display: block;
  @media (max-width: 1024px) {
    vertical-align: baseline;
  }
`;

const shouldAutoplay = window.innerWidth >= 768;
const preloadValue = shouldAutoplay ? 'auto' : 'none';

export const GalleryVideo = (props: Props) => {
  return (
    <GalleryVideoBackground
      autoPlay={shouldAutoplay}
      preload={preloadValue}
      poster={props.galleryCoverUrlHover}
      muted
      loop
    >
      <source src={props.galleryVideoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </GalleryVideoBackground>
  );
};
