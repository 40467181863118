import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import Modal from 'src/client/components/modals/Modal/Modal';
import { useSelector } from '../../redux/modules/helpers/useSelector';
import { addDashesToUUID } from 'src/shared/converters';
import { useJoinUrl } from 'src/client/components/buttons/JoinButton';
import { logoUrl } from 'src/client/helpers/urls/logoUrl';

const resolveSubscription = (sub, sites) => {
  let domain;

  if (sub) {
    const activeSite = sites.find((cSite) => addDashesToUUID(cSite.UUID) === sub.siteUUID);
    domain = activeSite?.domain.toLowerCase();
  }

  return domain ? { ...sub, domain } : undefined;
};

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexBoxItem = styled.div`
  flex-grow: 1;
`;

const WelcomeBackBox = styled.div`
  height: 100%;
  max-width: 575px;
  padding: 0 54px;
`;

const SiteLogo = styled.img`
  margin: 12px 0;
  max-width: 230px;
`;

const WelcomeMessage = styled.h4`
  font-weight: 700;
  margin: 4px 0 12px;
`;

const Text = styled.p`
  margin: 0 0 1px;

  :last-of-type {
    margin: 0 0 36px;
  }

  > span {
    text-decoration: underline;
  }
`;

const Button = styled.a`
  font-size: 18px;
  letter-spacing: 0;
  margin: 0 0 24px;
  padding: 12px 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  width: 350px;
`;

const NetworkSitesWrapper = styled.div`
  position: absolute;
  bottom: 12px;
`;

export type GuestGatewayModalProps = {
  className?: string;
  onClose: () => void;
};

function GuestGatewayModal(props: GuestGatewayModalProps) {
  const { className = '', onClose } = props;

  const { cdnUrl } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);
  const sites = useSelector((state) => state.sites.items);
  const { user } = useSelector((state) => state.auth);

  const [genericJoinUrl] = useJoinUrl({ campaign: 'guest-gateway-modal', overrideSite: '' });
  const [siteJoinUrl] = useJoinUrl({ campaign: 'guest-gateway-modal' });

  const activeSub = useMemo(() => resolveSubscription(user?.subscriptions[0], sites), [sites, user?.subscriptions]);
  const expiredSub = useMemo(
    () =>
      resolveSubscription(
        user?.expiredSubscriptions.find((sub) => sub.siteUUID === addDashesToUUID(site.UUID)),
        sites,
      ),
    [site.UUID, sites, user?.expiredSubscriptions],
  );
  const firstExpiredSub = useMemo(() => resolveSubscription(user?.expiredSubscriptions[0], sites), [
    sites,
    user?.expiredSubscriptions,
  ]);

  let text;
  let domain;
  let expireDate;

  if (activeSub) {
    text = 'current';
    domain = activeSub.domain;
    expireDate = '';
  }

  if (expiredSub) {
    text = 'previous';
    domain = expiredSub.domain;
    ({ expireDate } = expiredSub);
  }

  if (!activeSub && !expiredSub && firstExpiredSub) {
    text = 'previous';
    domain = firstExpiredSub.domain;
    ({ expireDate } = firstExpiredSub);
  }

  const expiration = useMemo(() => (expireDate ? moment(expireDate).format('MMM Do, YYYY') : ''), [expireDate]);

  return (
    <Modal
      altCloseButton
      dialogClassName={`${className} guest-modal-dialog`}
      modalBodyClassName={`${className} guest-modal-body`}
      onHide={onClose}
      show
    >
      <FlexBox>
        <FlexBoxItem>
          <img src={`${cdnUrl}/img/guest-gateway.jpg`} alt="" />
        </FlexBoxItem>
        <FlexBoxItem>
          <WelcomeBackBox>
            <SiteLogo alt={site.name} src={logoUrl(cdnUrl, site, true)} />
            <WelcomeMessage>{`Welcome Back ${user ? user.firstName : ''}`.trim()}!</WelcomeMessage>
            {expiration && <Text>Your account expired on {expiration}</Text>}
            <Text>
              As a {text} MetArt Network (<span>{domain}</span>) member you have been automatically logged in as a
              Guest.
            </Text>
            <Button
              className="btn btn-primary"
              data-value="regular"
              href={siteJoinUrl}
              onClick={onClose}
              rel="noopener noreferrer"
              target="_blank"
            >
              {`Gain Access to ${site.name}`}
            </Button>
            {user?.hasAnyValidSubscription && (
              <Button
                className="btn btn-secondary"
                data-value="regular"
                href={genericJoinUrl}
                onClick={onClose}
                rel="noopener noreferrer"
                target="_blank"
              >
                View All MetArt Network Sites
              </Button>
            )}
            <NetworkSitesWrapper>
              <img src={`${cdnUrl}/img/guest-gateway-sites.png`} alt="" />
            </NetworkSitesWrapper>
          </WelcomeBackBox>
        </FlexBoxItem>
      </FlexBox>
    </Modal>
  );
}

export default styled(GuestGatewayModal)`
  text-align: center;

  &.guest-modal-dialog {
    width: 100%;
  }

  &.guest-modal-body {
    margin: 0;
    padding: 4px;
  }
`;
