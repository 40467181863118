import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { MobileExpandableMenuItem } from './MobileExpandableMenuItem';
import { faWebcam } from '@fortawesome/pro-solid-svg-icons';
import { useSelector, useSettingsSelector } from '../../../redux';
import { MobileMenuCameraThumbnailCard } from 'src/client/components/cards/CameraThumbnailCard';
import { useGenerateCamUrlWithParams } from 'src/client/helpers/urls/useGenerateCamUrlWithParams';
import { ThemeOptions } from 'src/client/components/Providers/ThemeProvider';
import { CamsResponse } from 'src/@types/Cam';
import { camLogoUrl } from 'src/client/helpers/urls/camLogoUrl';

type Props = {
  withNewBadge?: boolean;
  provider: CamsResponse;
};

export const MobileCamProviderLiveGirls = ({
  withNewBadge,
  provider: { siteCams, provider, domain, description: providerLabel },
}: Props) => {
  const cdnUrl = useSelector((state) => state.app.cdnUrl);
  const theme = useSettingsSelector<ThemeOptions>('template');

  const generateCamUrlWithParams = useGenerateCamUrlWithParams({ trackingPosition: 'mobileMenu', provider });
  const [shownCams, setShownCams] = useState<number>(0);

  const onViewAllCamsClick = () => {
    window.open(generateCamUrlWithParams('/cams'), '_blank');
  };

  const calculateShownCamsByScreenHeight = () => {
    if (window.innerHeight < 685) {
      return 4;
    } else if (window.innerHeight < 820) {
      return 6;
    }

    return 8;
  };

  useEffect(() => {
    const onWindowResize = () => setShownCams(calculateShownCamsByScreenHeight());
    onWindowResize();

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <MobileExpandableMenuItem
      activeNestedItem={`mobile-live-cam-girls-${provider}`}
      icon={faWebcam}
      menuLabel={`${domain} Live Girls`}
      withNewBadge={withNewBadge}
    >
      <ContentWrapper>
        <ProviderLogo src={camLogoUrl(cdnUrl, domain, theme)} alt={providerLabel} />
        <CamsWrapper>
          {siteCams.slice(0, shownCams).map((cam) => (
            <MobileMenuCameraThumbnailCard
              provider={provider}
              camTrackingPosition={'mobileMenu'}
              cam={cam}
              eventContentType="livecam"
              key={cam.id}
            />
          ))}
        </CamsWrapper>
        <SeeAllButton onClick={onViewAllCamsClick}>
          See All <br /> {providerLabel} Girls
        </SeeAllButton>
      </ContentWrapper>
    </MobileExpandableMenuItem>
  );
};

const ProviderLogo = styled.img`
  max-height: 3em;
  max-width: 75%;
  width: 100%;
  margin: 0 auto 0.8em;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -2px;
  padding: 0 1.5em 0.75em;
`;

const CamsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, minmax(min-content, 1fr));

  justify-content: center;
  gap: 0.75em;
  margin: 0.75em 0;
`;

const SeeAllButton = styled.button`
  border: none;
  background-color: #53a779;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  border-radius: 2px;
  font-size: 0.75rem;
  padding: 3px 0;
`;
